import { memo, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import { Checkbox, Radio, TableRow } from "@mui/material";
import Td from "./Td";

/**
 * Memo function to avoid this component rerender  at least the list be updated
 */
function areEqual(prevProps, nextProps) {
  if (
    prevProps.data?.id === nextProps.data?.id &&
    prevProps.selector === nextProps.selector &&
    prevProps.isSelected === nextProps.isSelected &&
    prevProps.isFocused === nextProps.isFocused &&
    prevProps.setSelectedRow === nextProps.setSelectedRow &&
    prevProps.handleKeyDown === nextProps.handleKeyDown
  ) {
    return true;
  }
  return false;
}

function Tr({
  data,
  selector,
  isFocused,
  isSelected,
  hideId,
  setSelectedRow,
  handleKeyDown,
}) {
  // Ref
  const radioButtonRef = useRef();

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    "& .MuiRadio-root": {
      padding: "5px",
    },
  }));

  useEffect(() => {
    if (isFocused) {
      radioButtonRef.current.focus();
    }
  }, [isFocused]);

  return (
    <StyledTableRow>
      {selector && (
        <Td
          key={data.id}
          data={
            selector === "checkbox" ? (
              <Checkbox
                checked={isSelected}
                onChange={() => setSelectedRow(data.id, data)}
              />
            ) : (
              <Radio
                checked={isSelected}
                inputRef={radioButtonRef}
                onClick={() => {
                  if (isSelected) {
                    setSelectedRow(0);
                  } else {
                    setSelectedRow(data.id, data);
                  }
                }}
                onKeyDown={handleKeyDown}
              />
            )
          }
        />
      )}
      {Object.keys(data).map((attr) => {
        if (
          (attr !== "selected" && attr !== "id") ||
          (attr === "id" && !hideId)
        ) {
          return <Td key={`${attr}-${data.id}`} data={data[attr]} />;
        }
        return null;
      })}
    </StyledTableRow>
  );
}

export default memo(Tr, areEqual);
