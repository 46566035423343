import { ORDER_ITEM_ADDON_TYPE } from "../../../helpers/const";
import {
  getItemsWithErrors,
  validateItem,
  validateWarehouse,
} from "../../../helpers/helperFunctions";

export function thereAreErrors(
  { customer, orderDetails, items, warehouseId, freight },
  IsPoRequired,
) {
  const errors = {
    customer: {
      idHasError: false,
      nameHasError: false,
    },
    orderDetails: {
      orderedByHasError: false,
      customerPoHasError: false,
    },
    freight: {
      freightAmountHasError: false,
      handlingChargeHasError: false,
    },
    items: [],
    hasItemsErrors: false,
    hasCustomerErrors: false,
    hasOrderDetailsErrors: false,
    hasWhError: false,
    hasFreightError: false,
  };

  if (!customer?.customerId) {
    errors.customer.idHasError = true;
    errors.hasCustomerErrors = true;
  }

  if (!customer?.customerName) {
    errors.customer.nameHasError = true;
    errors.hasCustomerErrors = true;
  }

  if (!orderDetails?.orderedBy) {
    errors.orderDetails.orderedByHasError = true;
    errors.hasOrderDetailsErrors = true;
  }

  if (!orderDetails?.customerPo && IsPoRequired) {
    errors.orderDetails.customerPoHasError = true;
    errors.hasOrderDetailsErrors = true;
  }

  if (+freight?.freightAmount >= 1000000) {
    errors.freight.freightAmountHasError = true;
    errors.hasFreightError = true;
  }

  if (freight?.handlingCharge >= 10000) {
    errors.freight.handlingChargeHasError = true;
    errors.hasFreightError = true;
  }

  const validatedItems = [...items].map((item) => validateItem(item));

  const itemsWithErrors = getItemsWithErrors(validatedItems);

  if (validatedItems.length > 0) errors.items = validatedItems;
  if (itemsWithErrors.length > 0) errors.hasItemsErrors = true;
  errors.hasWhError = validateWarehouse(items, warehouseId);

  return errors;
}

export function checkItemsErrors(itemsAttributes, ln, currentItemsErrors) {
  if (currentItemsErrors.length > 0) {
    const updatedErrors = currentItemsErrors.map((item) => {
      if (item.ln === ln) {
        let currentErrors = [...item.errors];
        let settedErrors = [];
        let wh = null;

        if (itemsAttributes.hasOwnProperty("productCode")) {
          if (itemsAttributes.productCode) {
            settedErrors = currentErrors.map((i) => {
              if (i.name === "product") {
                return {
                  ...i,
                  error: false,
                  message: "",
                };
              }
              return i;
            });
            currentErrors = settedErrors;
          }
        }

        if (itemsAttributes.hasOwnProperty("d")) {
          if (itemsAttributes.d !== " " && itemsAttributes.d) {
            settedErrors = currentErrors.map((i) => {
              if (i.name === "d") {
                return {
                  ...i,
                  error: false,
                  message: "",
                };
              }
              return i;
            });
            currentErrors = settedErrors;
          }
        }

        if (itemsAttributes.hasOwnProperty("tq")) {
          if (itemsAttributes.tq || itemsAttributes.tq === 0) {
            settedErrors = currentErrors.map((i) => {
              if (i.name === "tq") {
                return {
                  ...i,
                  error: false,
                  message: "",
                };
              }
              return i;
            });
            currentErrors = settedErrors;
          }
        }

        if (itemsAttributes.hasOwnProperty("qrd")) {
          if (itemsAttributes.qrd) {
            settedErrors = currentErrors.map((i) => {
              if (i.name === "qrd") {
                return {
                  ...i,
                  error: false,
                  message: "",
                };
              }
              return i;
            });
            currentErrors = settedErrors;
          }
        }

        if (itemsAttributes.hasOwnProperty("bo")) {
          if (itemsAttributes.bo || itemsAttributes.bo === 0) {
            settedErrors = currentErrors.map((i) => {
              if (i.name === "bo") {
                return {
                  ...i,
                  error: false,
                  message: "",
                };
              }
              return i;
            });
            currentErrors = settedErrors;
          }
        }

        if (itemsAttributes.hasOwnProperty("item_price")) {
          if (
            itemsAttributes.item_price ||
            itemsAttributes.item_price === "0"
          ) {
            settedErrors = currentErrors.map((i) => {
              if (i.name === "item_price") {
                return {
                  ...i,
                  error: false,
                  message: "",
                };
              }
              return i;
            });
            currentErrors = settedErrors;
          }
        }

        if (itemsAttributes.hasOwnProperty("description")) {
          if (itemsAttributes.description) {
            settedErrors = currentErrors.map((i) => {
              if (i.name === "description") {
                return {
                  ...i,
                  error: false,
                  message: "",
                };
              }
              return i;
            });
            currentErrors = settedErrors;
          }
        }

        if (itemsAttributes.hasOwnProperty("wh")) {
          wh = itemsAttributes.wh;
        }

        return {
          ...item,
          errors: currentErrors,
          wh: wh ? wh : item.wh,
        };
      }
      return item;
    });

    const hasItemsErrors = updatedErrors.filter((v) => {
      const errors = v.errors.filter((i) => i.error);
      if (errors.length > 0) {
        return true;
      } else {
        return false;
      }
    });

    const hasWhError = updatedErrors.find(
      (e) =>
        e.wh.id !== updatedErrors[0].wh.id &&
        e.itemType !== ORDER_ITEM_ADDON_TYPE,
    );

    return {
      errors: updatedErrors,
      hasItemsErrors: hasItemsErrors.length > 0 ? true : false,
      hasWhError: hasWhError ? true : false,
    };
  }

  return {
    errors: [],
    hasItemsErrors: false,
    hasWhError: false,
  };
}
