import { defaultOrderItemErrors } from "../api/models/ItemsModel";
import { ORDER_ITEM_INVENTORY_TYPE } from "./const";

const createItemRow = ({
  lineItem,
  index,
  isSubItem,
  productCode,
  wh,
  warehouseId,
  availableProducts,
  isLocked,
}) => ({
  id: lineItem?.id,
  isSubItem,
  index,
  itemType: lineItem?.item_type,
  ln: lineItem?.line_num,
  productCode,
  description: lineItem?.description,
  wh,
  warehouse_id: warehouseId,
  s: lineItem?.price_type,
  d: lineItem?.discount_schedule || "Z",
  tq: parseInt(lineItem?.transfer_qty) || 0,
  qrd: parseInt(lineItem?.qty_ordered) || 0,
  bo: parseInt(lineItem?.qty_backordered) || 0,
  avail: parseInt(lineItem?.product?.qty_available) || 0,
  item_price: Number(lineItem?.item_price) || 0,
  discount: lineItem?.discount || 0,
  ext: Number(lineItem?.price) || 0,
  weight: Number(lineItem?.weight) || 0,
  unitWeight:
    lineItem?.qty_ordered > lineItem?.qty_backordered
      ? Number(
          lineItem?.weight /
            (lineItem?.qty_ordered - lineItem?.qty_backordered),
        )
      : 0,
  wsurch: Number(lineItem?.price) + Number(lineItem?.surcharge) || 0,
  warehouses: [],
  availableProducts,
  availableDiscounts: [],
  hasPriceOverride: false,
  isLocked,
  errors: defaultOrderItemErrors,
});

const formatItems = (items, subOrderStatus, type, wh) => {
  const rows = [];
  const shouldBeLocked = subOrderStatus > 2;

  const addSubItems = ({
    subItems,
    index,
    itemProductCode,
    wh,
    warehouseId,
    isLocked,
  }) => {
    subItems.forEach((item) => {
      rows.push(
        createItemRow({
          lineItem: item,
          index,
          isSubItem: true,
          productCode: item.product?.product_code || itemProductCode,
          wh,
          warehouseId,
          availableProducts: item.product?.product_code
            ? [item.product?.product_code]
            : [],
          isLocked,
        }),
      );
    });
  };

  if (type === "subOrder" || type === "quote") {
    items.forEach(({ item, subitems }, index) => {
      const productCode =
        type === "subOrder" ? item?.product?.product_code : item?.product_code;
      const isLocked = type === "subOrder" ? shouldBeLocked : true;
      const warehouse =
        type === "subOrder"
          ? {
              id: item?.product?.warehouse_id,
              name: item?.product?.warehouse_name,
            }
          : wh;
      const warehouseId = type === "subOrder" ? item?.product?.warehouse_id : 0;

      rows.push(
        createItemRow({
          lineItem: item,
          index,
          isSubItem: false,
          productCode,
          wh: warehouse,
          warehouseId,
          availableProducts: [productCode],
          isLocked,
        }),
      );

      if (subitems) {
        addSubItems({
          subItems: subitems,
          index,
          productCode,
          wh: warehouse,
          warehouseId,
          isLocked,
        });
      }
    });
  } else if (type === "appendedOrder") {
    items.forEach((item, index) => {
      if (item.item_type === ORDER_ITEM_INVENTORY_TYPE) {
        rows.push(
          createItemRow({
            lineItem: item,
            index,
            isSubItem: false,
            productCode: item.product?.product_code,
            wh: {
              id: item?.product?.warehouse_id,
              name: item?.product?.warehouse_name,
            },
            warehouseId: item?.product?.warehouse_id,
            availableProducts: item.product?.product_code
              ? [item.product?.product_code]
              : [],
            isLocked: shouldBeLocked,
          }),
        );
      }
    });
  }

  return rows;
};

export default formatItems;
