import React from "react";
import { useEffect, useContext, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Spinner from "react-bootstrap/Spinner";
import { apiGet } from "../../api/apiGet";
import { apiPost } from "../../api/apiPost";
import { BASE_API_URL } from "../../api/baseUrl";
import { LoginContext } from "../../contexts/LoginContext";
import { formStyle } from "../../theming/formStyle";
import { AlertMessageContext } from "../../components/atoms/AlertMessage/AlertMessage";
import { getCookie } from "../../helpers/helperFunctions";

function Login(props) {
  const { isAuthenticated, setAuthenticated, username, setUsername, setGroups } =
    useContext(LoginContext);
  const alertMessage = useContext(AlertMessageContext);

  const allgroups = props.groups



  // States

  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [chekingLogin, setCheckLogin] = useState(true);

  useEffect(() => {
    async function checkLogin() {
      try {
        setCheckLogin(true);
        const login = await apiGet.checkLogin();
        if (login?.status === 200) {
          setAuthenticated(true);
          const groups = await apiGet.getGroups();  
            let tempGroups = []
            for (let group of groups) {
              if (allgroups.includes(group)) {
                tempGroups = [...tempGroups, group]
              }
            }    
            setGroups(tempGroups);
          const u = getCookie("username");
          setUsername(u);
        }
        setCheckLogin(false);
      } catch (error) {
        if (error.message !== "Incorrect Credentials") {
          alertMessage.handleOpen(
            "login-check-failed",
            "danger",
            error.message,
            5000,
          );
        }
        setCheckLogin(false);
      }
    }
    if (!isAuthenticated) {
      checkLogin();
    }
  }, [isAuthenticated]);


  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const csrfmiddlewaretoken = await apiGet.authToken();
      if (csrfmiddlewaretoken) {
        const login = await apiPost.login({
          username,
          password,
          csrfmiddlewaretoken,
        });
        if (login.status === 200) {
          const user = await apiGet.checkLogin();
          if (user.status === 200) {
            apiPost.authCookie(username, 360);
            const groups = await apiGet.getGroups();  
            let tempGroups = []
            for (let group of groups) {
              if (allgroups.includes(group)) {
                tempGroups = [...tempGroups, group]
              }
            }    
            setGroups(tempGroups);
            setAuthenticated(true);
          }
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alertMessage.handleOpen(
        "login-check-failed",
        "danger",
        error.message,
        5000,
      );
    }
  };

  if (chekingLogin) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="grow" variant="primary" />
      </div>
    );
  }

  return (
    <div className="Login">
      <div className="row">
        <div className="col-md-6 mx-auto mt-5">
          <div className="card card-body container-sm">
            <div className="text-center">
              <h3>Login</h3>
              <form className="form" onSubmit={onSubmit}>
                <p>Please enter your credentials to login.</p>
                <div className="form-group mx-5 my-4">
                  <TextField
                    className="form-control"
                    id="outlined-basic"
                    label="Username"
                    variant="outlined"
                    type="text"
                    value={username}
                    InputLabelProps={formStyle.inputLabelProps}
                    style={formStyle.inputStyle}
                    onChange={(e) => setUsername(e.currentTarget.value)}
                  />
                </div>
                <div className="form-group mx-5 my-4">
                  <TextField
                    className="form-control"
                    id="outlined-basic"
                    label="Password"
                    type="password"
                    variant="outlined"
                    autoComplete="new-password"
                    value={password}
                    InputLabelProps={formStyle.inputLabelProps}
                    style={formStyle.inputStyle}
                    onChange={(e) => setPassword(e.currentTarget.value)}
                  />
                </div>
                <div className="form-group mx-5 my-4">
                  {isAuthenticated ? (
                    <div className="mx-auto bg-success">Authenticated</div>
                  ) : (
                    <div className="mx-auto bg-danger text-white">
                      Not Authenticated<br></br>
                    </div>
                  )}
                </div>
                <Button
                  variant="contained"
                  className="mx-5 my-4 bg-info submit"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? "Logging in..." : "Log In"}
                </Button>
                <p>
                  {" "}
                  <a
                    href={BASE_API_URL + "/users/password_reset/"}
                    target="_blank"
                  >
                    Reset password here if needed
                  </a>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Login;
