import { useEffect, memo, useRef } from "react";
import { Col } from "react-bootstrap";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField } from "@mui/material";
import { formStyle } from "../../../theming/formStyle";

function DataGridDateFilter({
  handleFilterRequest,
  dateFilter,
  focusOnDate,
  setFocusOnDate,
}) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startDateError, setStartDateError] = useState("");
  const [endDateError, setEndDateError] = useState("");

  // Refs
  const startDateRef = useRef();
  const endDateRef = useRef();

  const validateDate = (date) => {
    if (date === null) {
      return "This field is required";
    } else {
      if (!dayjs(date).isValid()) {
        return "This date has not a correct format";
      }
    }
    return "";
  };

  const validateDates = (finalDate) => {
    setStartDateError(validateDate(startDate));
    setEndDateError(validateDate(finalDate));
  };

  useEffect(() => {
    if (startDate && endDate && !startDateError && !endDateError) {
      handleFilterRequest("dates", [startDate, endDate]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, startDateError, endDateError]);

  useEffect(() => {
    if (focusOnDate) {
      startDateRef.current.focus();
    }
  }, [focusOnDate]);

  return (
    <>
      <Col md={2}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            label={dateFilter?.inputs[0].label}
            className="muiDatePicker"
            inputFormat="MM/DD/YYYY"
            name={dateFilter?.inputs[0].name}
            value={startDate}
            minDate={"1996-01-01"}
            inputRef={startDateRef}
            onChange={(date) => {
              setStartDate(date);
              setStartDateError(validateDate(date));
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                style={formStyle.inputStyle}
                InputLabelProps={formStyle.inputLabelProps}
                error={startDateError ? true : false}
                helperText={startDateError}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    endDateRef.current.focus();
                  }
                }}
                onBlur={() => {
                  setFocusOnDate(false);
                }}
              />
            )}
          />
        </LocalizationProvider>
      </Col>
      <Col md={2}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            label={dateFilter?.inputs[1].label}
            className="muiDatePicker"
            inputFormat="MM/DD/YYYY"
            name={dateFilter?.inputs[1].name}
            value={endDate}
            minDate={"1996-01-01"}
            inputRef={endDateRef}
            onChange={(date) => {
              setEndDate(date);
              validateDates(date);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                style={formStyle.inputStyle}
                InputLabelProps={formStyle.inputLabelProps}
                error={endDateError ? true : false}
                helperText={endDateError}
              />
            )}
          />
        </LocalizationProvider>
      </Col>
    </>
  );
}

export default memo(DataGridDateFilter);
