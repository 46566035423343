import { useState, useContext, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSectionRef, setFieldRef } from "../store/slices/refsSlice";
import { AlertMessageContext } from "../components/atoms/AlertMessage/AlertMessage";
import {
  searchProductsById,
  setItem,
  productChangeEvent,
  getManufacturedItems,
  whChangeEvent,
  getProductPriceById,
  dChangeEvent,
  priceOverride,
  customerIdChangeHandler,
  getXrefFromProductCode,
  getAvailableWarehousesForProduct,
  getDiscountSchedulesForProduct,
} from "../store/slices/item/itemSlice";
import { calculateBackOrdered } from "../store/slices/item/item";
import {
  ORDER_ITEM_INVENTORY_TYPE,
  ORDER_ITEM_DESCRIPTION_TYPE,
  ITEMS_SECTION_REF,
  ORDER_ITEM_ADDON_TYPE,
} from "../helpers/const";
import {
  setAutoSaveTriggerChanged,
  setShippingRulesTrigger,
  setVerifyFreightDetailsTriggerChanged,
} from "../store/slices/eventHandlerSlice";

import {
  apiGet
} from "../api/apiGet"
/**
 * Note: In the future I'll pass all the items logic in this rehusable hook
 */
function useItem({ item }) {
  // Context
  const alertNotification = useContext(AlertMessageContext);

  // Local states
  const [oldPrice, setOldPrice] = useState(0)
  const [productCode, setProductCode] = useState("");
  const [productError, setProductError] = useState({ error: false, msg: "" });
  const [dError, setDError] = useState({ error: false, msg: "" });
  const [tqError, setTqError] = useState({ error: false, msg: "" });
  const [qrdError, setQrdError] = useState({ error: false, msg: "" });
  const [boError, setBoError] = useState({ error: false, msg: "" });
  const [itemPriceError, setPriceError] = useState({ error: false, msg: "" });
  const [descriptionError, setDescriptionError] = useState({
    error: false,
    msg: "",
  });
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState(0);
  const [substitutionItemArray, setSubstitutionItemArray] = useState([])
  const [substitutionValue, setSubstitutionValue] = useState(0)
  // const [manufacturedItems, setManufacturedItems] = useState([])

  // Redux
  const dispatch = useDispatch();
  const { subOrderId } = useSelector((state) => state.subOrder);
  const { quoteId } = useSelector((state) => state.quote);
  const { fieldRef } = useSelector((state) => state.refs);
  const { items: itemsErrors } = useSelector((state) => state.validations);
  const { items, refCodes } = useSelector((state) => state.items);
  const {
    customerId,
    customerData: { hasXRefItems },
  } = useSelector((state) => state.customer);

  const {
    productPrice: { triggerChanged: priceTrigger },
  } = useSelector((state) => state.eventHandler);



  // Refs
  const productRef = useRef();
  const whInputRef = useRef();
  const maxSubsRef = useRef(1);
  const sInputRef = useRef();
  const dInputRef = useRef();
  const tqInputRef = useRef();
  const qrdInputRef = useRef();
  const boInputRef = useRef();
  const priceInputRef = useRef();
  const descriptionRef = useRef();
  const subsCountRef = useRef()

  useEffect(() => {
    if (hasXRefItems && customerId && !item.referenceCode && item.productCode) {
      dispatch(
        getXrefFromProductCode({
          customerId,
          productCode: item.productCode,
        }),
      );
    }
  }, [hasXRefItems, item.referenceCode, item.productCode, customerId]);

  useEffect(() => {
    if (oldPrice > 0) {
      dispatch(getProductPriceById({
        s: "S",
        d: item.d,
        price: oldPrice,
        ln: item.ln,
        substitution: substitutionValue
      }))
    }
  }, [oldPrice])

  const setLineItemsChanged = () => {
    if (subOrderId) {
      dispatch(setAutoSaveTriggerChanged({ itemsChanged: true }));
    }
  };

  /**
   * function to call the product info, discount and price info
   * @param {string} value Product to find information for
   */
  async function handleProductChange(value, isSUbstitution, subValue) {
    var s = undefined
    var sub = substitutionValue
    if (isSUbstitution === true) {
      s = "S"
    } else {
      sub = 0
    }

    dispatch(
      productChangeEvent({
        productCode: value,
        itemType: item.itemType,
        ln: item.ln,
        substitution: sub || 0,
        s: s,
        p: subValue || 0
      }),
    ).then((response) => {
      if (!response?.error) {
        setLineItemsChanged();
        setProductCode(value)
        whInputRef.current.focus();
      }
    })


  }

  async function handleManufacturedItems(value, res) {

    dispatch(
      getManufacturedItems({product_code: value, qrd: item.qrd, bo: item.bo, ln: item.ln}),
    ).then((response) => {
      
      if (!response?.error) {
        
        return true
      }
    });
  }

  async function handleItemSubs(value, d, priceOverride, itemType, priceType, subOrderId, company, whId) {
    let sub_secs = null

    if (customerId) {
    const response = await apiGet.getItemSubstitutions(
      value,
      d,
      priceOverride,
      customerId,
      itemType,
      priceType,
      subOrderId,
      company,
      whId,
      substitutionValue,
    ).then(
      (response) => {
        setSubstitutionItemArray([])

        if (response.subs) {
          sub_secs = Object.values(response.subs);
          subsCountRef.current = response.count
          for (var i = 0; i < response.count; i++) {
            let curr = sub_secs[i];
            let avail = Object.values(curr.availability);
            let availString = "";
            maxSubsRef.current = response.count

            for (var f = 0; f < avail.length; f++) {
              if (f != avail.length - 1) {
                availString += "\n  - " + avail[f].name + ": " + avail[f].avail + ", Cost: " + avail[f].cost + ", "
              } else {
                availString += "\n  - " + avail[f].name + ": " + avail[f].avail + ", Cost: " + avail[f].cost
              }
            }

            let map = {
              productCode: curr.product_code,
              description: curr.description,
              substitutionCode: curr.substitution_code,
              additionalPercOff: curr.additional_precent_off,
              minLevel: curr.minimum_level,
              availability: availString,
              cost: parseFloat(avail[0].cost.replace("$", ""))
            }

            setSubstitutionItemArray(prevArray => [...prevArray, map])
          }

        } else {

          setSubstitutionItemArray([]);
          subsCountRef.current = 0;
        }
      }
    
    )
    return response
  } else {
    return undefined
  }
    
  }

  /**
   * function to set the WH value
   * @param {string} value Selected wh value
   */
  async function handleWhChange(wh) {
    dispatch(whChangeEvent({ wh, ln: item.ln })).then((response) => {
      if (!response?.error) {
        setLineItemsChanged();
        sInputRef.current.focus();
      }
    });
  }

  /**
   * function to set the S value
   * @param {string} value Selected s value
   */
  async function handleSChange(value) {
    if (!item.productCode) {
      alertNotification.handleOpen(
        "handle-s-change-error",
        "danger",
        "You can't select a S value without having selected a product before.",
        5000,
      );
    } else {
      if (value.name === "S") {
        if (!item.d.trim()) {
          dispatch(
            getProductPriceById({
              s: "S",
              d: null,
              price: item.item_price,
              ln: item.ln,
            }),
          ).then((response) => {
            if (!response?.error) {
              setLineItemsChanged();
            }
          });
        } else {
          dispatch(
            setItem({ attributesToUpdate: { s: "S" }, ln: item.ln }),
          ).then((response) => {
            if (!response?.error) {
              setLineItemsChanged();
              tqInputRef.current.focus();
            }
          });
        }
      } else {
        dispatch(
          getProductPriceById({
            s: value.name,
            d: item.d,
            price: null,
            ln: item.ln
          }),
        ).then((response) => {
          if (!response?.error) {
            setLineItemsChanged();
            if (value.name) {
              dInputRef.current.focus();
            } else {
              tqInputRef.current.focus();
            }
          }
        });
      }
    }
  }

  /**
   * function to set the TQ value
   * @param {number} value Selected td value
   */
  async function handleTqChange(value) {
    dispatch(setItem({ attributesToUpdate: { tq: value }, ln: item.ln })).then(
      () => {
        setLineItemsChanged();
      },
    );
  }

  /**
   * function to set the D value
   * @param {string} value Selected d value
   */
  async function handleDChange(value) {
    if (value.trim()) {
      dispatch(dChangeEvent({ ln: item.ln, dValue: value })).then(
        (response) => {
          if (!response?.error) {
            setLineItemsChanged();
            tqInputRef.current.focus();
          }
        },
      );
    }
  }

  /**
   * function to set the QRD value
   * @param {number} value Selected qrd value
   */
  async function handleQrdChange(value) {
    let qrdValue = isNaN(+value) ? 0 : +value;
    const backOrdered = calculateBackOrdered({
      qrdValue,
      availValue: item.avail,
    });

    dispatch(
      setItem({
        attributesToUpdate: {
          weight: item.unitWeight * (qrdValue - backOrdered),
          ext: item.item_price * (qrdValue - backOrdered),
          wsurch: item.item_price * (qrdValue - backOrdered),
          qrd: value,
          bo: backOrdered,

        },
        ln: item.ln,
      }),
    ).then(() => {
      dispatch(
        setShippingRulesTrigger({
          triggerChanged: true,
        }),
      );
      dispatch(setVerifyFreightDetailsTriggerChanged());
      setLineItemsChanged();
    });
  }

  /**
   * function to set the BO value
   * @param {number} value Selected bo value
   */
  async function handleBoChange(value, oldValue) {

    const attributesToUpdate = () => {
      var backOrder = value

      if (backOrder > item.qrd) {
        return {
          bo: item.qrd,
          avail: item.avail - item.qrd,
          ext: 0,
          wsurch: 0,
          weight:0
        }
      }
      else if (isNaN(backOrder)) {
        return {
          avail: item.qrd,
          qrd: item.qrd,
          bo: 0,
          ext: item.item_price * item.qrd,
          wsurch: item.item_price * (item.qrd),
          weight: item.unitWeight * (item.qrd),
        };
      } else if (backOrder === 0) {
        var avail = item.qrd
        if (item.avail < item.qrd) {
          avail = item.qrd
        }
        return {
          avail: avail,
          qrd: item.qrd,
          bo: value,
          ext: item.item_price * item.qrd,
          weight: item.unitWeight * (item.qrd - backOrder),
          wsurch: item.item_price * (item.qrd - backOrder)
        };
      } else if (backOrder > 0 && backOrder > oldValue) {
        return {
          avail: (item.avail + oldValue) - backOrder,
          qrd: item.qrd,
          bo: value,
          ext: item.item_price * (item.qrd - backOrder),
          weight: item.unitWeight * (item.qrd - backOrder),
          wsurch: item.item_price * (item.qrd - backOrder)
        };
      } else if (backOrder > 0 && backOrder < oldValue) {
        return {
          avail: item.avail + Math.abs(oldValue - backOrder),
          qrd: item.qrd,
          bo: value,
          ext: item.item_price * (item.qrd - backOrder),
          weight: item.unitWeight * (item.qrd - backOrder),
          wsurch: item.item_price * (item.qrd - backOrder)
        }
      }



      let newQrd = 0;
      let finalPrice = 0;
      let weight = 0;
      let newAvail = 0

      newQrd = item.qrd
      weight = item.weight
      newAvail = item.qrd

      return {
        avail: newAvail,
        qrd: newQrd,
        ext: finalPrice,
        bo: backOrder,
        weight,
      };
    };

    dispatch(
      setItem({ attributesToUpdate: attributesToUpdate(), ln: item.ln }),
    ).then(() => {
      setLineItemsChanged();
    });
  }

  /**
   * function to set the price value
   * @param {string} value Selected price value
   */
  async function handlePriceChange(value) {
    dispatch(
      setItem({
        attributesToUpdate: {
          item_price: value,
          hasPriceOverride: true,
          ext: value * (item.qrd - item.bo),
          wsurch: value * (item.qrd - item.bo),
        },
        ln: item.ln,
      }),
    ).then((response) => {
      if (!response?.error) {
        setLineItemsChanged();
      }
    });
    dispatch(setShippingRulesTrigger({
      focusOutted: true,
    }))
  }

  /**
   * Function to update prices values when the field item_price is overrided
   */
  async function checkPriceOverride(price, s) {
    await dispatch( priceOverride({ ln: item.ln, price })).then((response) => {
      if (!response?.error) {
        if (!response?.payload) {
          alertNotification.handleOpen(
            "check-price-override",
            "danger",
            "Discount not found",
            5000,
          );
        }
      }
    })
  }

  /**
   * function to set the description value
   * @param {string} value Selected description value
   */
  async function handleDescriptionChange(e) {
    var value = e.target.value
    const pointer = e.target.selectionStart;
    const element = e.target;
    window.requestAnimationFrame(() => {
      element.selectionStart = pointer;
      element.selectionEnd = pointer;

    });
    dispatch(
      setItem({ attributesToUpdate: { description: value }, ln: item.ln }),
    ).then(() => {
      setLineItemsChanged();
    });
  }

  const getAvailableWarehouses = () => {
    dispatch(
      setItem({ attributesToUpdate: { loadingWarehouses: true }, ln: item.ln }),
    );
    dispatch(
      getAvailableWarehousesForProduct({
        productCode: item.productCode,
        ln: item.ln,
      }),
    );
  };

  const getDiscountSchedules = () => {
    dispatch(
      setItem({
        attributesToUpdate: { loadingDiscountSchedules: true },
        ln: item.ln,
      }),
    );
    dispatch(
      getDiscountSchedulesForProduct({
        productCode: item.productCode,
        ln: item.ln,
      }),
    );
  };

  useEffect(() => {
    if (fieldRef === `item-${item.ln}`) {
      if (item.itemType === ORDER_ITEM_INVENTORY_TYPE) {
        productRef.current.focus();
      } else if (
        item.itemType === ORDER_ITEM_DESCRIPTION_TYPE ||
        item.itemType === ORDER_ITEM_ADDON_TYPE
      ) {
        descriptionRef.current.focus();
      }
    }

    if (fieldRef === `item-${item.ln}-tq`) {
      tqInputRef.current.focus();
    } else if (fieldRef === `item-${item.ln}-qrd`) {
      qrdInputRef.current.focus();
    } else if (fieldRef === `item-${item.ln}-bo`) {
      boInputRef.current.focus();
    }
  }, [fieldRef]);

  useEffect(() => {
    if (productCode) {
      dispatch(
        setItem({ attributesToUpdate: { loadingProducts: true }, ln: item.ln }),
      );
      const debouncing = setTimeout(() => {
        dispatch(
          searchProductsById({
            productCode: productCode.toUpperCase(),
            itemLn: item.ln,
          }),
        );
      }, 1000);
      return () => clearTimeout(debouncing);
    } else {
      dispatch(
        setItem({
          attributesToUpdate: { loadingProducts: false },
          ln: item.ln,
        }),
      );
    }
  }, [productCode]);

  useEffect(() => {
    const thisItemValidator = itemsErrors.find((i) => i.ln === item.ln);

    if (thisItemValidator) {
      thisItemValidator.errors.forEach((e) => {
        if (e.name === "product" && e.error) {
          setProductError({ error: true, msg: e.message });
        } else if (e.name === "product" && !e.error) {
          setProductError({ error: false, msg: "" });
        }

        if (e.name === "d" && e.error) {
          setDError({ error: true, msg: e.message });
        } else if (e.name === "d" && !e.error) {
          setDError({ error: false, msg: "" });
        }

        if (e.name === "tq" && e.error) {
          setTqError({ error: true, msg: e.message });
        } else if (e.name === "tq" && !e.error) {
          setTqError({ error: false, msg: "" });
        }

        if (e.name === "qrd" && e.error) {
          setQrdError({ error: true, msg: e.message });
        } else if (e.name === "qrd" && !e.error) {
          setQrdError({ error: false, msg: "" });
        }

        if (e.name === "bo" && e.error) {
          setBoError({ error: true, msg: e.message });
        } else if (e.name === "bo" && !e.error) {
          setBoError({ error: false, msg: "" });
        }

        if (e.name === "itemPrice" && e.error) {
          setPriceError({ error: true, msg: e.message });
        } else if (e.name === "itemPrice" && !e.error) {
          setPriceError({ error: false, msg: "" });
        }

        if (e.name === "description" && e.error) {
          setDescriptionError({ error: true, msg: e.message });
        } else if (e.name === "description" && !e.error) {
          setDescriptionError({ error: false, msg: "" });
        }
      });
    }
  }, [itemsErrors]);

  useEffect(() => {
    setPrice(Number(item.item_price).toFixed(2));
  }, [item.item_price]);

  /**
   *  When a different user is selected this resets all the rows and adds new pricing.
   */
  useEffect(() => {
    if (
      priceTrigger &&
      item.productCode &&
      item.itemType === ORDER_ITEM_INVENTORY_TYPE
    ) {
      dispatch(customerIdChangeHandler({ item, substitution: substitutionValue })).then(handleItemSubs(item.productCode));
    }
  }, [item, priceTrigger]);

  const onKeyDownHandler = (event, ref, fieldName) => {
    if (event.key === "Enter" && !event.shiftKey) {
      //item substitution


      if (event.target.name === "bo") {
        if (item.s === "S") {
          ref.current.focus();
        } else {
          if (items.length === item.ln) {
            dispatch(setFieldRef("addItemSelector"));
          } else {
            dispatch(setFieldRef(`item-${item.ln + 1}`));
          }
        }
      } else if (event.target.name === "s") {
        if (event.target.value === "D") {
          ref.current.focus();
        } else {
          tqInputRef.current.focus();
        }
      } else if (
        event.target.name === "item_price" ||
        event.target.name === "description"
      ) {
        if (items.length === item.ln) {
          dispatch(setFieldRef("addItemSelector"));
        } else {
          dispatch(setFieldRef(`item-${item.ln + 1}`));
        }
      } else {
        ref.current.focus();
      }
    }

    if (event.key === "ArrowDown") {
      event.preventDefault();

      if (items.length > item.ln) {
        dispatch(setFieldRef(`item-${item.ln + 1}-${fieldName}`));
      }
    }

    if (event.key === "ArrowUp") {
      event.preventDefault();

      if (item.ln > 1) {
        dispatch(setFieldRef(`item-${item.ln - 1}-${fieldName}`));
      }
    }

    if (event.target.name === "description") {

    }
  };

  const onClickHandler = () => {
    //item substitution

    dispatch(setSectionRef(ITEMS_SECTION_REF));
    dispatch(setFieldRef(""));
  };

  return {
    useSelector,
    onKeyDownHandler,
    setProductCode,
    setDescription,
    setPrice,
    setDError,
    onClickHandler,
    handleWhChange,
    handleSChange,
    handleDChange,
    handleTqChange,
    handleQrdChange,
    handleBoChange,
    handlePriceChange,
    checkPriceOverride,
    handleDescriptionChange,
    handleProductChange,
    handleItemSubs,
    getAvailableWarehouses,
    getDiscountSchedules,
    setSubstitutionValue,
    setOldPrice,
    handleManufacturedItems,

    price,
    oldPrice,
    productCode,
    refCode: refCodes[item.productCode],
    productError,
    dError,
    tqError,
    qrdError,
    boError,
    itemPriceError,
    description,
    descriptionError,
    substitutionItemArray,
    substitutionValue,

    // Refs
    productRef,
    whInputRef,
    sInputRef,
    dInputRef,
    tqInputRef,
    qrdInputRef,
    boInputRef,
    priceInputRef,
    descriptionRef,
    subsCountRef,
    maxSubsRef,
  };
}

export default useItem;
