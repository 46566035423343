import { memo } from "react";
import { BsArrowReturnRight } from "react-icons/bs";
import { AiFillDelete } from "react-icons/ai";
import { ButtonGroup, IconButton, TextField, Tooltip } from "@mui/material";
import { TbTruckDelivery } from "react-icons/tb";
import ErrorIcon from "../ErrorIcon/ErrorIcon";
import ConfirmationAlert from "../ConfirmationAlert/ConfirmarionAlert";
import useItem from "../../../hooks/useItem";
import useItemButtons from "../../../hooks/useItemButtons";
import ToolTip from "../ToolTip";

function DescriptionOrderItem({ item, allowedActions }) {
  const {
    descriptionError,
    descriptionRef,
    savedAsOrder,
    handleDescriptionChange,
    onClickHandler,
    useSelector,
    onKeyDownHandler,
  } = useItem({ item });

  const { deletingProcess } =
    useItemButtons(item);

  return (
    <>
    <table className="wrapper" style={{width: "100%", height: 70}}>
      <tr key={item.ln} className="orderItem-row-mounted" style={{borderBottom:"none", }}>
        <td className="align-middle" colSpan={2} style={{ width: 30 }}>
          <BsArrowReturnRight />
        </td>
        <td className="align-middle" colSpan={3}>
          <TextField
            id="filled-basic"
            disabled={item.isLocked}
            style={{ width: "100%" }}
            name="description"
            value={item.description}
            onChange={(e) => handleDescriptionChange(e)}
            className={
              item.description === "Mounted On" ? "input-read-only" : ""
            }
            error={descriptionError.error}
            inputRef={descriptionRef}
            onKeyDown={(event) => onKeyDownHandler(event)}
            onClick={onClickHandler}
          />
          {descriptionError.error && (
            <ToolTip
              title={descriptionError.msg}
              textColor="#fff"
              backgroundColor="#d32f2f"
            >
              <span>
                <ErrorIcon />
              </span>
            </ToolTip>
          )}
        </td>
        <td colSpan={9}></td>
        <td>
          <ButtonGroup aria-label="action-button-group" className="mt-4">

            <Tooltip title="Mount" arrow placement="top">
              <IconButton color="primary" disabled size="small">
                <TbTruckDelivery />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete" arrow placement="top">
              <IconButton
                color="primary"
                onClick={() => {
                  deletingProcess();
                }}
                disabled={!allowedActions.delete || item.isLocked}
                size="small"
              >
                <AiFillDelete />
              </IconButton>
            </Tooltip>
          </ButtonGroup>
        </td>
      </tr>

      </table>
    </>
  );
}

export default memo(DescriptionOrderItem, (prevProps, nextProps) => {
  if (
    prevProps.item.id === nextProps.item.id &&
    prevProps.item.ln === nextProps.item.ln &&
    prevProps.item.isLocked === nextProps.item.isLocked &&
    prevProps.item.description === nextProps.item.description &&
    prevProps.item.item_price === nextProps.item.item_price &&
    prevProps.item.errors.description === nextProps.item.errors.description &&
    prevProps.allowedActions.delete === nextProps.allowedActions.delete
  ) {
    return true;
  }
  return false;
});
