import { AvailableProductCodes } from "./AvailableProductCodes";

export const defaultOrderItemErrors = [
  {
    name: "product",
    error: false,
    message: "",
  },
  {
    name: "s",
    error: false,
    message: "",
  },
  {
    name: "d",
    error: false,
    message: "",
  },
  {
    name: "tq",
    error: false,
    message: "",
  },
  {
    name: "qrd",
    error: false,
    message: "",
  },
  {
    name: "bo",
    error: false,
    message: "",
  },
  {
    name: "itemPrice",
    error: false,
    message: "",
  },
  {
    name: "description",
    error: false,
    message: "",
  },
];
export const defaultOrderItem = {
  id: 0,
  isSubItem: false,
  index: 0,
  itemType: 0,
  ln: 1,
  productCode: "",
  description: "",
  wh: {
    id: 0,
    name: "TN",
  },
  s: " ",
  d: null,
  tq: 0,
  qrd: 0,
  bo: 0,
  avail: 0,
  item_price: 0,
  discount: 0,
  ext: 0,
  weight: 0,
  unitWeight: 0,
  wsurch: 0,
  warehouses: [],
  availableProducts: [],
  availableDiscounts: [],
  hasPriceOverride: false,
  isLocked: false,
  errors: defaultOrderItemErrors,
  loadingProducts: false,
  loadingWarehouses: false,
  loadingDiscountSchedules: false,
};
