import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Button } from "react-bootstrap";

import { TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { formStyle } from "../../../theming/formStyle";

import {
  getWarehouseInformation,
  setFreightRater,
} from "../../../store/slices/freight/freightSlice";

import "./FreightDetails.scss"

function FreightRaterHeader({ fetchRaterOptions, cZarZipInputRef }) {
  const {
    subOrder: { warehouse_id },
  } = useSelector((state) => state.subOrder);
  const {
    weight,
    billOfLading,
    bolDetail: { appended_weight = 0 },
    freightRater: {
      czarZip,
      longName,
      destinationZip,
      destinationState,
      testCzarZip,
      testDestinationZip,
    },
  } = useSelector((state) => state.freight);
  
  // Redux
  const dispatch = useDispatch();

  const destinationZipInputRef = useRef();
  const rateButtonRef = useRef();

  useEffect(() => {
    dispatch(getWarehouseInformation(warehouse_id));
  }, [warehouse_id, dispatch]);

  useEffect(() => {
    dispatch(
      setFreightRater({
        testCzarZip: czarZip,
        testDestinationZip: destinationZip,
      }),
    );
  }, [czarZip, destinationZip, dispatch]);

  return (
    <>
      <Row style={{ marginTop: "20px" }} className="freightRaterPop">
        <Col>
          <TextField
            label="Warehouse#"
            style={formStyle.inputStyle}
            InputLabelProps={formStyle.inputLabelProps}
            value={warehouse_id}
            disabled
          />
        </Col>

        <Col>
          <TextField
            label="Warehouse State"
            style={formStyle.inputStyle}
            InputLabelProps={formStyle.inputLabelProps}
            fullWidth
            value={longName}
            disabled
          />
        </Col>

        <Col>
          <TextField
            label="Warehouse Zip"
            style={formStyle.inputStyle}
            inputRef={cZarZipInputRef}
            InputLabelProps={formStyle.inputLabelProps}
            value={testCzarZip}
            onChange={(e) =>
              dispatch(setFreightRater({ testCzarZip: e.target.value }))
            }
            placeholder={czarZip}
            helperText={czarZip !== testCzarZip ? `Order Zip: ${czarZip}` : ""}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
                if (!event.shiftKey) {
                  destinationZipInputRef.current.focus();
                }
              }
            }}
          />
        </Col>

        <Col>
          <TextField
            label="Ship to Zip"
            style={formStyle.inputStyle}
            inputRef={destinationZipInputRef}
            InputLabelProps={formStyle.inputLabelProps}
            value={testDestinationZip}
            onChange={(e) =>
              dispatch(setFreightRater({ testDestinationZip: e.target.value }))
            }
            placeholder={destinationZip}
            helperText={
              destinationZip !== testDestinationZip
                ? `Order Zip: ${destinationZip}`
                : ""
            }
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
                rateButtonRef.current.focus();
              }
            }}
          />
        </Col>

        <Col>
            <TextField
              label="Ship to State"
              style={formStyle.inputStyle}
              InputLabelProps={formStyle.inputLabelProps}
              fullWidth
              value={destinationState}
              disabled
            />
          </Col>
        <Col>
          <TextField
            label="Weight"
            style={formStyle.inputStyle}
            InputLabelProps={formStyle.inputLabelProps}
            value={weight + appended_weight}
            disabled
          />
        </Col>

          
        </Row>
        

        <Row style={{ marginTop: "20px" }}>
          <Col>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Date"
                className="muiDatePicker"
                inputFormat="MM/DD/YYYY"
                // value={date}
                disabled
                renderInput={(params) => (
                  <TextField
                    {...params}
                    style={formStyle.inputStyle}
                    InputLabelProps={formStyle.inputLabelProps}
                  />
                )}
              />
            </LocalizationProvider>
          </Col>
          <Col >
            <TextField
              label="BOL# (Shipping Ticket)"
              style={formStyle.inputStyle}
              InputLabelProps={formStyle.inputLabelProps}
              value={billOfLading}
              disabled
            />
          </Col>
          <Col className="d-flex justify-content-end">
          <Button
            variant="primary"
            className="mx-3 "
            ref={rateButtonRef}
            disabled={weight === 0}
            style={{ maxHeight: 48, marginTop: "" }}
            onClick={fetchRaterOptions}
            onKeyDown={(e) => {
              if (e.code === "Enter") {
                e.preventDefault();
                if (!e.shiftKey) {
                  fetchRaterOptions();
                }
              }
            }}
          >
            Rate
          </Button>
        </Col>
        </Row>
        <Row style={{ marginTop: "20px" }}>
        
        </Row>
    </>
  );
}

export default FreightRaterHeader;
