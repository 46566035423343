import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiGet } from "../../../api/apiGet";

export const getWarehouses = createAsyncThunk(
  "warehouse/all",
  async (params, thunkAPI) => {
    try {
      const warehouses = await apiGet.warehouseList(params);

      return warehouses;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        axiosError: error,
        customMsg: "Error getting the warehouses.",
      });
    }
  },
);

const initialState = {
  count: 0,
  warehouses: [],
  loading: false,
  error: null,
};

export const warehouseSlice = createSlice({
  name: "warehouseSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getWarehouses.pending, (state, action) => ({
        ...state,
        loading: true,
        error: null,
      }))
      .addCase(getWarehouses.fulfilled, (state, action) => {
        state.count = action.payload.count;
        state.warehouses = action.payload.results;
        state.loading = false;
        state.error = null;
      });
  },
});

export default warehouseSlice.reducer;
