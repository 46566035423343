import { Alert, Checkbox, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { Button, Card, Col, Row } from "react-bootstrap";
import "./InvoiceAccess.scss"
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoaderSpinner from "../../../components/atoms/LoaderSpinner";
import { checkDefaultCustomerNum, doInvoiceAction, getAllCustomerEmails } from "../../../store/slices/AccountsReceivable/InvoiceAccessSlice";
import { AlertMessageContext } from "../../../components/atoms/AlertMessage/AlertMessage";
import ConfirmSendInvoicePopup from "../../../components/atoms/ConfirmSendInvoicePopup/ConfirmSendInvoicePopup";
import { apiGet } from "../../../api/apiGet";
import { doLastBusinessDay } from "./InvoiceAccessHelpers";

const InvoiceAccess = () => {
    //state variables - we will need at least one per field, possibly two per comment below in some cases.
    const alertNotification = useContext(AlertMessageContext)

    const [customerNum, setCustomerNum] = useState("")
    const [transactionNum, setTransactionNum] = useState("")
    const [transactionNumType, setTransactionNumType] = useState("invoice")
    const [deliveryMethod, setDeliveryMethod] = useState("email")
    const [emailAddress, setEmailAddress] = useState("")
    const [fax, setFax] = useState("")
    const [faxSender, setFaxSender] = useState("")
    const [faxRecipient, setFaxRecipient] = useState("")
    const [faxCompany, setFaxCompany] = useState("")
    const [bodyText, setBodyText] = useState("Thank you for your business, we hope to work with you again soon.")
    const [lowerDate, setLowerDate] = useState("")
    const [upperDate, setUpperDate] = useState("")
    const [lowerRange, setLowerRange] = useState("")
    const [upperRange, setUpperRange] = useState("")
    const [grouping, setGrouping] = useState("inv-grouping")

    const [showConfirmPopup, setShowConfirmPopup] = useState(false)
    const [invoiceCount, setInvoiceCount] = useState(0)
    const [defaultCustomerNum, setDefaultCustomerNum] = useState(-1)

    const [generateDisabled, setGenerateDisabled] = useState(true)
    const [doLastBusinessDaySt, setDoLastBusinessDaySt] = useState(false)

    //error states
    const [emailError, setEmailError] = useState(false)
    const [faxError, setFaxError] = useState(true)
    const [faxSenderError, setFaxSenderError] = useState(true)
    const [faxRecError, setFaxRecError] = useState(true)
    const [faxCompError, setFaxCompError] = useState(true)
    const [dateError, setDateError] = useState(false)
    const [rangeError, setRangeError] = useState(false)

    const [showErrorAlert, setShowErrorAlert] = useState(false)

    const [allEmails, setAllEmails] = useState([])
    const [checkedEmails, setCheckedEmails] = useState([])

    //redux -  we will need at least one for now to represent loading
    const dispatch = useDispatch()
    const { loading } = useSelector((state) => state.loaderSpinner)
    //event handlers -  if there are too many of these we can try to abstract out to redux
    useEffect(() => {
        if (deliveryMethod === "fax") {
            setShowErrorAlert((faxError || faxCompError || faxRecError || faxSenderError))

        } else {
            setShowErrorAlert(false)
        }

    }, [emailError, faxError, faxCompError, faxRecError, faxSenderError, deliveryMethod])

    useEffect(() => {


        if (lowerDate.length === 10 && upperDate.length === 10) {
            const dateLower = new Date(lowerDate)
            const dateUpper = new Date(upperDate)

            // Calculate the difference in milliseconds
            const diffInMs = Math.abs(dateUpper - dateLower);

            // Convert to days
            const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

            if (customerNum.length >= 3 && diffInDays > 30) {
                setDateError(true)
                //error handling for date range too large
            } else if (!(customerNum.length >= 3) && diffInDays > 1) {
                setDateError(true)
                //error for date range too large
            } else {
                setDateError(false)
            }
        } else if ((lowerDate.length <= 10 || upperDate.length <= 10) && (lowerDate.length > 0 || upperDate.length > 0)) {
            setDateError(true)
            //error handling needed
        } else if (customerNum.length >= 3 && !(transactionNum.length > 0) && !(lowerRange.length > 0 || upperRange.length > 0) && (lowerDate.length <= 10 || upperDate.length <= 10)) {
            setDateError(true)
        } else {
            setDateError(false)
        }

    }, [transactionNum, customerNum, lowerDate, upperDate, lowerRange, upperRange, deliveryMethod])

    useEffect(() => {
        if (lowerRange.length >= 4 && upperRange.length >= 4) {
            if (parseInt(upperRange) - parseInt(lowerRange) > 25 || parseInt(upperRange) - parseInt(lowerRange) < 0) {
                setRangeError(true)
            } else {
                setRangeError(false)
            }
        } else if ((lowerRange.length < 4 || upperRange.length < 4) && !(lowerRange.length === 0 && upperRange.length === 0)) {
            setRangeError(true)
        } else {
            setRangeError(false)
        }
    }, [lowerRange, upperRange, deliveryMethod])

    useEffect(() => {
        if (["fax", "email"].includes(deliveryMethod)) {
            setGrouping("inv-grouping inv-grouping-border")

        } else {
            setGrouping("inv-grouping")
        }
    }, [deliveryMethod])

    useEffect(() => {
        if (deliveryMethod === "email" && emailAddress) {
            var pattern = /^[a-zA-Z_.^0-9]+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
            setEmailError(!emailAddress.match(pattern))
        } else {
            setEmailError(false)
        }
    }, [emailAddress, deliveryMethod])

    useEffect(() => {
        if (deliveryMethod === "fax") {
            let errorFound = false
            if (fax.length >= 10) {
                setFaxError(false)
            } else {
                errorFound = true
                setFaxError(true)
            }
            if (faxCompany.length > 0) {
                setFaxCompError(false)
            } else {
                errorFound = true
                setFaxCompError(true)
            }
            if (faxRecipient.length > 0) {
                setFaxRecError(false)
            } else {
                errorFound = true
                setFaxRecError(true)
            }
            if (faxSender.length > 0) {
                setFaxSenderError(false)
            } else {
                errorFound = true
                setFaxSenderError(true)
            }
        }
    }, [fax, faxCompany, faxRecipient, faxSender, deliveryMethod])

    useEffect(() => {

        if (customerNum.length === 0 && transactionNum.length === 0 && lowerDate.length === 0 && upperDate.length === 0 && lowerRange.length === 0 && upperRange.length === 0) {
            setGenerateDisabled(true)
        } else {
            setGenerateDisabled(false)
        }

    }, [customerNum, transactionNum, lowerDate, upperDate, lowerRange, upperRange])

    async function checkDefaultCustomerNum() {
        const response = await apiGet.hasCustomerNum()
        setDefaultCustomerNum(response.data)
        setCustomerNum(response.data !== -1 ?  response.data : "")
    }

    useEffect(() => {
        checkDefaultCustomerNum()
    }, [])



    const handleDeliveryMethodChange = (value) => {
        setDeliveryMethod(value)
    }

    const processDate = (value) => {
        //format as date
        let res = value.val.replace(/[^0-9]/g, '')
        if (res.length > 4) {
            res = res.slice(0, 4) + "-" + res.slice(4, res.length)
        }

        if (res.length > 7) {
            res = res.slice(0, 7) + "-" + res.slice(7, res.length)
        }

        if (value.switch === "lower") {
            setLowerDate(res)
        } else {
            setUpperDate(res)
        }
    }

    const sendInvoices = () => {
        setShowConfirmPopup(false)
        dispatch(doInvoiceAction({
            customerNum,
            transactionNum,
            transactionNumType,
            deliveryMethod,
            lowerDate,
            upperDate,
            lowerRange,
            upperRange,
            emailAddress: !emailAddress ? checkedEmails : [emailAddress],
            bodyText,
            countQuery: false,
            doLastBusinessDay: doLastBusinessDaySt
        })).then((response) => {
            if (!response?.error) {
                alertNotification.handleOpen(
                    "success-savign-as-sub-order",
                    "success",
                    "Invoices processed succesfully!",
                    5000,
                )
            }
        }
        )
        setDoLastBusinessDaySt(false)
    }


    //submit logic
    const onSubmit = (doLastBusinessDay = false) => {
        if (doLastBusinessDay !== doLastBusinessDaySt) {
            setDoLastBusinessDaySt(doLastBusinessDay)
        }

        dispatch(doInvoiceAction({
            customerNum: doLastBusinessDay ? "" : customerNum,
            transactionNum,
            transactionNumType,
            deliveryMethod,
            lowerDate: doLastBusinessDay ? "" : lowerDate,
            upperDate: doLastBusinessDay ? "" : upperDate,
            lowerRange: doLastBusinessDay ? "" : lowerRange,
            upperRange: doLastBusinessDay ? "" : upperRange,
            emailAddress: doLastBusinessDay ? "" : emailAddress,
            bodyText,
            countQuery: true,
            doLastBusinessDay: doLastBusinessDay ? doLastBusinessDay : doLastBusinessDaySt
        })).then((response) => {
            if (!response?.error) {
                setInvoiceCount(response.payload)
                setShowConfirmPopup(true)

            }
        })
    }

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (customerNum.length >= 3) {
                dispatch(getAllCustomerEmails({customerNum: customerNum})).then((response) => {
                    if (!response?.error) {
                        let TempChecked = []
                        for (let email of response.payload) {
                            if (email.pref === true && !TempChecked.includes(email.email)) {
                                TempChecked.push(email.email)
                            }
                        }

                        setCheckedEmails(TempChecked)
                        setAllEmails(response.payload)
                    }
                })
            } else {
                setAllEmails([])
                setCheckedEmails([])
            }
          }, 1000);

          return () => clearTimeout(timeoutId);
    }, [customerNum])

    const handleBoxClick = (value) => {

        
        for (let email of allEmails) {
            
            if (email.email === value && checkedEmails.includes(value)) {
                setCheckedEmails(checkedEmails.filter(email => email !== value))
            } else if (email.email === value &&  !checkedEmails.includes(email)) {
                setCheckedEmails([...checkedEmails, email.email])
            }
        }
    }

    

    return (
        <>
            <LoaderSpinner loading={loading} />
            <Card className="mt-5 inv-acc-card">
                <div className="last-bus-day">

                        <h2 style={{border: "None", paddingBottom: 0}}>Deliver all Invoices from last business day:</h2>
  

                        <Button
                            onClick={() => onSubmit(true)}
                        >
                            Send Invoices
                        </Button>
                </div>
                <div>
                    <i>**Note: This will <i>not</i> work for protire.</i>
                </div>
            </Card>
            <Card className="mt-5 inv-acc-card">
                {showErrorAlert && <Alert severity="error" >There are errors with the {deliveryMethod === "email" ? "email" : "fax information"} provided. Please see the fields below and resubmit.</Alert>}
                {dateError && customerNum.length >= 3 && <Alert severity="error" className="mt-3">There are errors with the date provided. The dates can not be more than 30 days apart.</Alert>}
                {dateError && customerNum.length < 3 && <Alert severity="error" className="mt-3">There are errors with the date provided. The dates can not be more than 1 day apart.</Alert>}
                {rangeError && <Alert severity="error" className="mt-3">There are errors with the invoice range provided. You can not search more than 25 invoices at a time.</Alert>}
                <h2>Send Invoices</h2>
                <Row className="inv-grouping">
                    {/* These fields are always shown */}
                    <Col className="inv-row">
                        {/* customer num */}
                        <Col className="span-100">

                            <InputLabel id="inv-field" error={customerNum.length > 0 && customerNum.length < 3 ? true : false}>Customer Number</InputLabel>
                            <TextField
                                disabled={defaultCustomerNum !== -1}
                                error={customerNum.length > 0 && customerNum.length < 3 ? true : false}
                                labelid="inv-field"
                                id="inv_field"
                                variant="filled"
                                inputprops={{ tabIndex: "1" }}
                                value={customerNum}
                                onChange={(e) => setCustomerNum(e.target.value.replace(/[^0-9]/g, ''))}
                            />

                            <InputLabel id="inv-field" error={dateError} className="mt-3">Lower Date Range</InputLabel>
                            <TextField
                                error={dateError}
                                labelid="inv-field"
                                id="lower_date"
                                variant="filled"
                                placeholder="YYYY-MM-DD"
                                inputprops={{ maxLength: 10, tabIndex: "5" }}
                                value={lowerDate}
                                onChange={(e) => processDate({ val: e.target.value, switch: "lower" })}
                            />

                        </Col>
                        {/* Number that will be provided */}
                        <Col className="span-100">
                            <InputLabel id="inv-field">Transaction Number</InputLabel>
                            <TextField
                                labelid="inv-field"
                                id="transaction_num"
                                variant="filled"
                                inputprops={{ tabIndex: "2" }}
                                value={transactionNum}
                                onChange={(e) => setTransactionNum(e.target.value.replace(/[^0-9]/g, ''))}
                            />

                            <InputLabel id="inv-field" error={dateError} className="mt-3">Upper Date Range</InputLabel>
                            <TextField
                                error={dateError}
                                labelid="inv-field"
                                id="upper_date"
                                variant="filled"
                                placeholder="YYYY-MM-DD"
                                inputprops={{ maxLength: 10, tabIndex: "6" }}
                                value={upperDate}
                                onChange={(e) => processDate({ val: e.target.value, switch: "upper" })}
                            />

                        </Col>
                        {/* Type of number being provided if one is provided (order, credit, invoice) */}
                        <Col className="span-100">
                            <InputLabel id="inv-field">Transaction # Type</InputLabel>
                            <Select
                                labelid="inv-field"
                                label="trans_num_type"
                                className="ch-w"
                                inputprops={{ tabIndex: "3" }}
                                value={transactionNumType}
                                onChange={(e) => setTransactionNumType(e.target.value)}
                            >
                                <MenuItem value="order">Order #</MenuItem>
                                <MenuItem value="credit">Credit #</MenuItem>
                                <MenuItem value="invoice">Invoice #</MenuItem>
                            </Select>
                            <InputLabel id="inv-field" error={rangeError} className="mt-3">Lower Trans # Range</InputLabel>
                            <TextField
                                error={rangeError}
                                labelid="inv-field"
                                id="lower_range"
                                variant="filled"
                                inputprops={{ maxLength: 8, tabIndex: "7" }}
                                value={lowerRange}
                                onChange={(e) => setLowerRange(e.target.value.replace(/[^0-9]/g, ''))}
                            />
                        </Col>
                        {/* Return method (email, fax, download) */}
                        <Col className="span-100">
                            <InputLabel id="inv-field">Delivery Method</InputLabel>
                            <Select
                                labelid="inv-field"
                                label="delivery_method"
                                className="ch-w"
                                inputprops={{ tabIndex: "4" }}
                                value={deliveryMethod}
                                onChange={(e) => handleDeliveryMethodChange(e.target.value)}
                            >
                                <MenuItem value="email">Email</MenuItem>
                                <MenuItem value="fax">Fax</MenuItem>
                                <MenuItem value="file">Download</MenuItem>
                                <MenuItem value="print">Print</MenuItem>
                            </Select>
                            <InputLabel id="inv-field" error={rangeError} className="mt-3">Upper Trans # Range</InputLabel>
                            <TextField
                                error={rangeError}
                                labelid="inv-field"
                                id="upper_range"
                                variant="filled"
                                inputprops={{ maxLength: 10, tabIndex: "8" }}
                                value={upperRange}
                                onChange={(e) => setUpperRange(e.target.value.replace(/[^0-9]/g, ''))}
                            />
                        </Col>
                    </Col>
                </Row>
                <Row className="flex-no-just">
                    
                    {allEmails.length > 0 && 
                    allEmails.map((email) => {
                        return (
                            <Col key={email.key}>
                                <FormGroup>
                                    <FormControlLabel
                                    label={email.email}
                               control={<Checkbox
                                    value={email.email}
                                    checked={checkedEmails.includes(email.email)}

                                    onClick={(e) => handleBoxClick(e.target.value)}
                                    />}
    />
                                </FormGroup>
                            </Col>
                        )
                    })}
                    
                </Row>

                <Row className={grouping}>
                    <Col className="no-pad">
                        {deliveryMethod === "email" && <h3>Optional Email Fields</h3>}
                    </Col>
                    <Col className="inv-row-bet">

                        {/* Address for who you are sending the email/fax to? !! Should I make this two seperate fields depending on what is selected so I can do input validation in real time? */}
                        {deliveryMethod === "email" &&
                            <div>
                                <InputLabel id="inv-field" error={emailError}>Recipient Email Address</InputLabel>
                                <TextField
                                    error={emailError}
                                    labelid="inv-field"
                                    type="email"
                                    id="rec_email_add"
                                    variant="filled"
                                    inputprops={{ tabIndex: "9" }}
                                    className="span-100"
                                    value={emailAddress}
                                    onChange={(e) => setEmailAddress(e.target.value)}

                                />
                            </div>
                        }
                    </Col>

                </Row>
                <Row>
                    <Col>
                        {/* Text to include in the body of a email or fax */}
                        {["fax", "email"].includes(deliveryMethod) &&
                            <div>
                                <InputLabel id="inv-field">Optional Message</InputLabel>
                                <TextField
                                    labelid="inv-field"
                                    id="body_text"
                                    variant="filled"
                                    className="span-100-nopad"
                                    inputprops={{ tabIndex: "14" }}
                                    value={bodyText}
                                    onChange={(e) => setBodyText(e.target.value)}
                                   
                                />
                            </div>
                        }
                    </Col>
                </Row>
                <Row className="mt-5 ">
                    <Col className="button-algn">
                        <Button
                            disabled={generateDisabled || (dateError || rangeError || (!(faxError || faxCompError || faxRecError || faxSenderError) ? deliveryMethod === "fax" : emailError))}
                            onClick={() => onSubmit()}
                            inputprops={{ tabIndex: "15" }}
                        >

                            {["fax", "email"].includes(deliveryMethod) ? "Send Invoices" : "Download/Print Invoices"}
                        </Button>
                    </Col>
                </Row>
            </Card>
            <ConfirmSendInvoicePopup
                show={showConfirmPopup}
                count={invoiceCount}
                lowerDate={lowerDate}
                upperDate={upperDate}
                customerNum={customerNum}
                transNum={transactionNum}
                transType={transactionNumType}
                deliveryMethod={deliveryMethod}
                lowerRange={lowerRange}
                upperRange={upperRange}
                emailAddress={checkedEmails.length > 1 ? checkedEmails : undefined}
                fax={fax}
                faxRecipient={faxRecipient}
                handleCancelEvent={() => setShowConfirmPopup(false)}
                handleConfirmEvent={() => sendInvoices()}
            />
        </>
    )
}

export default InvoiceAccess;