import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiGet } from "../../../api/apiGet";

export const checkDefaultCustomerNum = createAsyncThunk(
    "invoices/defaultCustomer",
    
    async ({}, thunkAPI) => {
        try {
            const response = await apiGet.hasCustomerNum()
            return response.data
        } catch (err) {
            return thunkAPI.rejectWithValue({
                axiosError: err,
                customMsg: "Error getting the default customer number.",
            });
        }
    }
)

export const getAllCustomerEmails = createAsyncThunk(
    "invoices/defaultCustomer",
    
    async ({customerNum}, thunkAPI) => {
        try {
            const response = await apiGet.allCustomerEmails(customerNum)
            return response.data
        } catch (err) {
            return thunkAPI.rejectWithValue({
                axiosError: err,
                customMsg: "Error getting the customer emails.",
            });
        }
    }
)


export const doInvoiceAction = createAsyncThunk(
    "invoices/print",
    async ({
        customerNum,
        transactionNum,
        transactionNumType,
        deliveryMethod,
        lowerDate,
        upperDate,
        lowerRange,
        upperRange,
        emailAddress,
        bodyText,
        countQuery,
        doLastBusinessDay
    }, thunkAPI) => {
        try {
            const response = await apiGet.invoices(
                customerNum,
                transactionNum,
                transactionNumType,
                deliveryMethod,
                lowerDate,
                upperDate,
                lowerRange,
                upperRange,
                emailAddress,
                bodyText,
                countQuery,
                doLastBusinessDay
            )
            if (response.data === 0) {
                throw new Error("No orders found for the entered parameters.")
            }
            

            if (deliveryMethod === "file" && !countQuery) {
                const url = window.URL.createObjectURL(new Blob([response.data]
                ));
                const link = document.createElement('a');
                const today = new Date();

                // Get the date components
                const year = today.getFullYear();
                const month = today.getMonth() + 1; // JavaScript months are 0-indexed
                const day = today.getDate();

                // Format the date string
                const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

                link.href = url;
                link.setAttribute('download', 'Invoices '+formattedDate+'.zip'); // Set the desired filename
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
            if (countQuery) {
                return response.data
            }

            return "Success"

        } catch (err) {
            if (err.message === "No orders found for the entered parameters.") {
                return thunkAPI.rejectWithValue({
                    axiosError: err,
                    customMsg: err.message,
                });
            }
            return thunkAPI.rejectWithValue({
                axiosError: err,
                customMsg: "Error processing the invoices.",
            });
        }
    }
);

export const accountsReceivableSlice = createSlice({
    name: "accountsReceivableSlice",
    initialState: {
        arLoading: false,
    },
    reducers: {
        setLoading: (state, action) => {
            state.arLoading = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(doInvoiceAction.pending, (state, action) => {
                state.arLoading = true
            })
            .addCase(doInvoiceAction.fulfilled, (state, action) => {
                state.arLoading = false
            })
            .addCase(doInvoiceAction.rejected, (state, action) => {
                console.log(action)
                state.arLoading = false
            })
    },
});

export default accountsReceivableSlice.reducer