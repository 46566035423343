import { memo } from "react";
import { ButtonGroup, IconButton, TextField, Tooltip } from "@mui/material";
import { AiFillDelete } from "react-icons/ai";
import {  TbTruckDelivery } from "react-icons/tb";
import ErrorIcon from "../ErrorIcon/ErrorIcon";
import ConfirmationAlert from "../ConfirmationAlert/ConfirmarionAlert";
import useItem from "../../../hooks/useItem";
import useItemButtons from "../../../hooks/useItemButtons";
import ToolTip from "../ToolTip";
import "./AddonOrderItem.scss";


function AddonOrderItem({ item, allowedActions }) {
  const {
    descriptionError,
    descriptionRef,
    handleDescriptionChange,
    onClickHandler,
    onKeyDownHandler,
  } = useItem({ item });

  const { deletingProcess} =
    useItemButtons(item);

  return (
    <>
    <table style={{width: "100%"}}>
      <tr key={item.ln} className="orderItem-row-mounted" style={{width:"100%"}}>
        <td className="align-middle" class="add-ln">{item.ln}</td>
        <td className="align-middle">
          <TextField
            id="filled-basic"
            disabled={item.isLocked}
            style={{ width: "100%" }}
            name="productCode"
            value="Add On"
            className={"input-read-only"}
          />
        </td>
        <td className="align-middle" colSpan={3}>
          <TextField
            id="filled-basic"
            disabled={item.isLocked}
            style={{ width: "100%" }}
            name="description"
            value={item.description}
            onChange={(e) => handleDescriptionChange(e.target.value)}
            className={"input-read-only"}
            error={descriptionError.error}
            inputRef={descriptionRef}
            onKeyDown={(event) => onKeyDownHandler(event)}
            onClick={onClickHandler}
          />
          {descriptionError.error && (
            <ToolTip
              title={descriptionError.msg}
              textColor="#fff"
              backgroundColor="#d32f2f"
            >
              <span>
                <ErrorIcon />
              </span>
            </ToolTip>
          )}
        </td>
        <td colSpan={9}></td>
        <td>
          <ButtonGroup aria-label="action-button-group" className="mt-4">

            <Tooltip title="Mount" arrow placement="top">
              <IconButton color="primary" disabled size="small">
                <TbTruckDelivery />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete" arrow placement="top">
              <IconButton
                color="primary"
                onClick={() => {
                    deletingProcess(item);
                }}
                disabled={!allowedActions.delete || item.isLocked}
                size="small"
              >
                <AiFillDelete />
              </IconButton>
            </Tooltip>
          </ButtonGroup>
        </td>
      </tr>
      </table>
    </>
  );
}

export default memo(AddonOrderItem, (prevProps, nextProps) => {
  if (
    prevProps.item.ln === nextProps.item.ln &&
    prevProps.item.isLocked === nextProps.item.isLocked &&
    prevProps.item.description === nextProps.item.description &&
    prevProps.item.errors.description === nextProps.item.errors.description &&
    prevProps.allowedActions.delete === nextProps.allowedActions.delete
  ) {
    return true;
  }
  return false;
});
