import { Alert } from "@mui/material"
import { Card } from "react-bootstrap"
import SuggestedOrderReport from "./SuggestedOrderReport"

const Purchasing = () => {

    return (
        <>
            <SuggestedOrderReport />
        </>
    )
}

export default Purchasing