import { useCallback, useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkBackordersProcess, setAllDeletedItems } from "../store/slices/item/itemSlice";
import { AlertMessageContext } from "../components/atoms/AlertMessage/AlertMessage";

import {
  deleteItems,
  reIndexItems,
  mountOnEvent,
} from "../store/slices/item/itemSlice";
import { setShippingRulesTrigger } from "../store/slices/eventHandlerSlice";

function useItemButtons(item) {
  // Context
  const alertNotification = useContext(AlertMessageContext);

  // Local states
  const [deletionMessages, setDeletionMessages] = useState([]);
  const [showDeleteItemPopup, setShowDeleteItemPopup] = useState(false);

  // Redux
  const dispatch = useDispatch();
  const { items } = useSelector((state) => state.items);

  /**
   * Function to update the state of items bafore to delete the item
   * @param {object} item order item to be deleted
   */
  const deleteItem = useCallback(
    (itemToDelete) => {
      if (!itemToDelete.isSubItem) {
        const allItemsToDelete = items.filter(
          (i) => i.index === itemToDelete.index,
        );
        const itemsToReIndex = items.filter(
          (i) => i.index !== itemToDelete.index,
        );
        dispatch(reIndexItems(itemsToReIndex))
        dispatch(
          setShippingRulesTrigger({
            triggerChanged: true,
            focusOutted: true,
          }),
        );

      } else if (itemToDelete.isSubItem) {

        const itemsToReIndex = items.filter((i) => i.ln !== itemToDelete.ln);
        dispatch(reIndexItems(itemsToReIndex));

      }
    },
    [items],
  );

  const deletingProcess = () => {
    if (item.id) {
      dispatch(
        checkBackordersProcess({
          productCode: item.productCode,
          itemType: item.itemType,
        }),
      ).then((response) => {
        if (response?.error) {
          alertNotification.handleOpen(
            "getting-backorder-status-error",
            "danger",
            response.error.message,
            5000,
          );
        } else {
          if (response.payload.length > 0) {
            setDeletionMessages([
              {
                ...response.payload,
                content: `${response.payload[0].message} You should inform to the plant/factory that the BO are no longer required. `,
              },
            ])
          };
        } 
        deleteItem(item)
        dispatch(setAllDeletedItems(item));
      }

      );
    } else {
      deleteItem(item);
      dispatch(setAllDeletedItems(item));
    }

  }

  

/**
 * Function to mount an item
 * @param {object} itemToMount order item to be mounted
 */
async function mountOn(itemToMount) {
  dispatch(mountOnEvent({ item: itemToMount }));
}

useEffect(() => {
  setShowDeleteItemPopup(false);
}, [item.id]);

return {
  deletingProcess,
  deleteItem,
  setShowDeleteItemPopup,
  deletionMessages,
  showDeleteItemPopup,
  mountOn,
};
}

export default useItemButtons;
