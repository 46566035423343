import { createSlice, current } from "@reduxjs/toolkit";
import {
  setCustomerId,
  setCustomerName,
  getCustomerById,
  setCustomerData,
} from "../customer/customerSlice";
import { setOrderDetails, getSubOrder } from "../subOrderSlice";
import { setItem, saveItem } from "../item/itemSlice";
import { setFreight } from "../freight/freightSlice";
import { thereAreErrors, checkItemsErrors } from "./validations";

const initialState = {
  submitProcess: false,
  customerPoIsRequired: false,
  customer: {
    idHasError: false,
    nameHasError: false,
  },
  orderDetails: {
    orderedByHasError: false,
    customerPoHasError: false,
  },
  freight: {
    freightAmountHasError: false,
    handlingChargeHasError: false,
  },
  items: [],
  hasItemsErrors: false,
  hasCustomerErrors: false,
  hasOrderDetailsErrors: false,
  hasWhError: false,
  hasFreightError: false,
};

export const validationsSlice = createSlice({
  name: "validationsSlice",
  initialState: initialState,
  reducers: {
    setErrors: (state, action) => {
      const errors = thereAreErrors(action.payload, state.customerPoIsRequired);
      state.submitProcess = true;
      state.customer = errors.customer;
      state.orderDetails = errors.orderDetails;
      state.freight = errors.freight;
      state.items = errors.items;
      state.hasItemsErrors = errors.hasItemsErrors;
      state.hasCustomerErrors = errors.hasCustomerErrors;
      state.hasOrderDetailsErrors = errors.hasOrderDetailsErrors;
      state.hasWhError = errors.hasWhError;
      state.hasFreightError = errors.hasFreightError;
    },
    resetSubmitProcess: (state) => {
      state.submitProcess = false;
    },
    resetFormValidations: (state) => initialState,
    setItemsError: (state, action) => {
      if (action.payload.hasItemsErrors) {
        state.items = action.payload.itemsErrors;
      }
      state.hasItemsErrors = action.payload.hasItemsErrors;
      state.hasWhError = action.payload.hasWhError;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setCustomerId, (state, action) => {
        if (!action.payload) {
          state.customer.idHasError = true;
        } else {
          state.customer.idHasError = false;
        }
      })
      .addCase(setCustomerName, (state, action) => {
        if (!action.payload) {
          state.customer.nameHasError = true;
        } else {
          state.customer.nameHasError = false;
        }
      })
      .addCase(setOrderDetails, (state, action) => {
        if (action.payload.hasOwnProperty("placed_by")) {
          if (!action.payload.placed_by) {
            state.orderDetails.orderedByHasError = true;
          } else {
            state.orderDetails.orderedByHasError = false;
          }
        }

        if (action.payload.hasOwnProperty("customer_po")) {
          if (state.customerPoIsRequired && !action.payload.customer_po) {
            state.orderDetails.customerPoHasError = true;
          } else {
            state.orderDetails.customerPoHasError = false;
          }
        }
      })
      .addCase(setItem.fulfilled, (state, action) => {
        const updatedErrors = checkItemsErrors(
          action.payload.attributesToUpdate,
          action.payload.ln,
          current(state.items),
        );
        state.items = updatedErrors.errors;
        state.hasWhError = updatedErrors.hasWhError;
        if (updatedErrors.errors.length > 0 && !updatedErrors.hasItemsErrors) {
          state.hasItemsErrors = false;
        }
      })
      .addCase(setFreight, (state, action) => {
        if (action.payload.hasOwnProperty("freightAmount")) {
          if (+action.payload.freightAmount >= 1000000) {
            state.freight.freightAmountHasError = true;
          } else {
            state.freight.freightAmountHasError = false;
          }
        }

        if (action.payload.hasOwnProperty("handlingCharge")) {
          if (+action.payload.handlingCharge >= 10000) {
            state.freight.handlingChargeHasError = true;
          } else {
            state.freight.handlingChargeHasError = false;
          }
        }
      })
      .addCase(getCustomerById.fulfilled, (state, action) => {
        state.customerPoIsRequired = action.payload.customerReqPo;
      })
      .addCase(setCustomerData, (state, action) => {
        state.customerPoIsRequired = action.payload.customerReqPo;
      })
      .addCase(getSubOrder.fulfilled, (state, action) => {
        state.customerPoIsRequired =
          action.payload.subOrderData.customer.require_po;
      })
      .addCase(saveItem.fulfilled, (state, action) => {
        state.items = action.payload.itemsErrors;
        state.hasItemsErrors = action.payload.hasItemsErrors;
        state.hasWhError = action.payload.hasWhError;
      });
  },
});

export const {
  setErrors,
  resetSubmitProcess,
  resetFormValidations,
  setItemsError,
} = validationsSlice.actions;
export default validationsSlice.reducer;
