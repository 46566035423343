import { createSlice } from "@reduxjs/toolkit";
import {
  applyShippingRules,
  verifyFreightDetails,
} from "./freight/freightSlice";
import { customerIdChangeHandler, getManufacturedItems, mountOnEvent } from "./item/itemSlice";
import { setErrors } from "./validations/validationsSlice";
import { getSubOrder, updateSubOrder } from "./subOrderSlice";

const initialState = {
  shippingRules: {
    shouldApplyShippingRules: false,
    triggerChanged: false,
    focusOutted: false,
  },
  verifyFreightDetails: {
    triggerChanged: false,
  },
  autoSaveOrder: {
    shouldAutosave: false,
    triggerChanged: false,
    focusOutted: false,
    itemsChanged: false,
  },
  productPrice: {
    triggerChanged: false,
  },
};

export const eventHandlerSlice = createSlice({
  name: "eventHandlerSlice",
  initialState,
  reducers: {
    setShippingRulesTrigger: (state, action) => {
      state.shippingRules = {
        ...state.shippingRules,
        ...action.payload,
      };
    },
    setVerifyFreightDetailsTriggerChanged: (state) => {
      state.verifyFreightDetails.triggerChanged = true;
    },
    setShouldAutosave: (state, action) => {
      state.autoSaveOrder.shouldAutosave = action.payload;
    },
    setAutoSaveTriggerChanged: (state, { payload }) => {
      state.autoSaveOrder.triggerChanged = true;
      if (payload?.itemsChanged) {
        state.autoSaveOrder.itemsChanged = true;
      }
    },
    setAutoSaveFocusOuttedTrue: (state) => {
      state.autoSaveOrder.focusOutted = true;
    },
    resetAutoSaveTrigger: (state) => {
      state.autoSaveOrder.triggerChanged =
        initialState.autoSaveOrder.triggerChanged;
      state.autoSaveOrder.focusOutted = initialState.autoSaveOrder.focusOutted;
    },
    setProductPriceTriggerChanged: (state) => {
      state.productPrice.triggerChanged = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(applyShippingRules.fulfilled, (state) => {
        state.shippingRules.triggerChanged = false;
        state.shippingRules.focusOutted = false;
      })
      .addCase(getSubOrder.pending, (state) => {
        state.shippingRules = initialState.shippingRules;
      })
      .addCase(verifyFreightDetails.fulfilled, (state) => {
        state.verifyFreightDetails = initialState.verifyFreightDetails;
      })
      .addCase(mountOnEvent.fulfilled, (state) => {
        state.autoSaveOrder.triggerChanged = true;
        state.autoSaveOrder.itemsChanged = true;
      })
      .addCase(getManufacturedItems.fulfilled, (state) => {
        state.autoSaveOrder.itemsChanged = true;
      })
      .addCase(setErrors, (state) => {
        state.autoSaveOrder.triggerChanged = false;
        state.autoSaveOrder.focusOutted = false;
      })
      .addCase(updateSubOrder, (state) => {
        state.autoSaveOrder.itemsChanged = false;
      })
      .addCase(customerIdChangeHandler.fulfilled, (state) => {
        state.productPrice.triggerChanged = false;
      });
  },
});

export const {
  setShippingRulesTrigger,
  setVerifyFreightDetailsTriggerChanged,
  setAutoSaveTriggerChanged,
  setAutoSaveFocusOuttedTrue,
  resetAutoSaveTrigger,
  setProductPriceTriggerChanged,
  setShouldAutosave,
} = eventHandlerSlice.actions;
export default eventHandlerSlice.reducer;
