import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Autocomplete } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllSubOrders,
  searchSubOrdersbyId,
  setLoadingAllSubOrders,
} from "../../../store/slices/subOrderSlice";
import { formStyle } from "../../../theming/formStyle";
import { searchProductsById } from "../../../store/slices/item/itemSlice";
import { endpoints } from "../../../api/endpoints";


function SubOrderSelector() {
  // States
  const [typedSubOrder, setTypedSubOrder] = useState();

  // Redux
  const { subOrderId, allSubOrders, loadingAllSubOrders } = useSelector(
    (state) => state.subOrder,
  );

  const dispatch = useDispatch();

  // Routes
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAllSubOrders());
    dispatch(searchProductsById({first: true}))
  }, [dispatch]);

  useEffect(() => {
    if (typedSubOrder) {
      dispatch(setLoadingAllSubOrders(true));
      const debouncing = setTimeout(() => {
        dispatch(searchSubOrdersbyId(typedSubOrder));
      }, 500);
      return () => clearTimeout(debouncing);
    } else {
      dispatch(setLoadingAllSubOrders(false));
    }
  }, [typedSubOrder, dispatch]);

  return (
    <Autocomplete
      className="autocomplete-input"
      options={allSubOrders}
      variant="filled"
      value={subOrderId ? subOrderId : null}
      getOptionLabel={(option) => option.toString()}
      onChange={(_event, value) => {
        if (value) {
          navigate(endpoints.ORDER_ENTRY + "/order/" + value);
        }
      }}
      renderInput={(params) => (
        <TextField
          label="Order ID"
          {...params}
          style={formStyle.inputStyle}
          InputLabelProps={formStyle.inputLabelProps}
          onChange={(e) => setTypedSubOrder(e.target.value)}
          InputProps={{
            ...params.InputProps,
            style: { textTransform: "uppercase" },
            endAdornment: (
              <>
                {loadingAllSubOrders ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

export default SubOrderSelector;
