/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomerDetails from "../../components/sections/CustomerDetails/CustomerDetails";
import CustomerOrders from "../../components/sections/CustomerOrders/CustomerOrders";
import CustomerQuotes from "../../components/sections/CustomerQuotes/CustomerQuotes";
import Heading from "../../components/sections/Heading/Heading";
import ARBalance from "../../components/sections/ARBalance/ARBalance";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AfterMarket from "../../components/atoms/AfterMarket";
import { endpoints } from "../../api/endpoints";
import {
  getCustomerById,
  resetCustomer,
  setLoadedCustomerDetails,
} from "../../store/slices/customer/customerSlice";
import XRefCode from "../../components/atoms/XRefCode";

const Customer = () => {
  // Routers
  const { id } = useParams();

  // Local states
  const [tab, setTab] = useState(0);

  // Redux
  const dispatch = useDispatch();
  const {
    loadedCustomerDetails,
    customerData: {
      name,
      aftermarketId,
      aftermarketName,
      billToDiller,
      hasXRefItems,
      itemXRefCode,
    },
  } = useSelector((state) => state.customer);

  useEffect(() => {
    if (id) {
      dispatch(getCustomerById(id)).then((response) => {
        if (!response?.error) {
          dispatch(setLoadedCustomerDetails(true));
        }
      });
    }
    return () => {
      dispatch(resetCustomer());
      dispatch(setLoadedCustomerDetails(false));
    };
  }, [id]);

  return (
    <div>
      <Row className="py-4">
        <Col sm={5}>
          <Heading title="Customer Details" subtitle={name} identifier={id} />
        </Col>
        <Col sm={3}>
          {aftermarketId ? (
            <AfterMarket
              id={aftermarketId}
              name={aftermarketName}
              billToDealer={billToDiller}
            />
          ) : (
            <></>
          )}
        </Col>
        <Col sm={2}>
          {hasXRefItems ? <XRefCode code={itemXRefCode} /> : <></>}
        </Col>
        <Col className="d-flex justify-content-end align-items-center">
          <Link to={endpoints.ORDER_ENTRY + `/customer/${id}/new-order/`}>
            <Button>New Order</Button>
          </Link>
        </Col>
      </Row>

      <CustomerDetails />

      {loadedCustomerDetails ? (
        <div className="mt-5">
          <ARBalance />
        </div>
      ) : (
        <div
          className="mt-5 d-flex align-items-center justify-content-center"
          style={{ height: 150 }}
        >
          <Spinner animation="grow" variant="primary" />
        </div>
      )}

      {loadedCustomerDetails && (
        <Tabs
          value={tab}
          onChange={(event, newValue) => setTab(newValue)}
          centered
        >
          <Tab label="Customer Orders" />
          <Tab label="Customer Quotes" />
        </Tabs>
      )}

      {tab === 0 && loadedCustomerDetails && <CustomerOrders />}
      {tab === 1 && loadedCustomerDetails && <CustomerQuotes />}
    </div>
  );
};

export default Customer;
