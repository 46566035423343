import { PREPAID_WEIGHT } from "../../../helpers/const";

export const formatCustomers = (customers) => {
  return customers.map((customer) => {
    return formatCustomer(customer);
  });
};

export const formatCustomer = (customer) => {
  const {
    street: billingStreet,
    route: billingRoute,
    city: billignCity,
    state: billingState,
    zip_code: billingZipCode,
  } = customer.billing_address;

  const comments = customer.contact_comments

  


  const {
    street: shippingStreet,
    route: shippingRoute,
    city: shippingCity,
    state: shippingState,
    zip_code: shippingZipCode,
  } = customer.shipping_address;

  return {
    id: customer.customer_num,
    name: customer.name,
    contactPhone: customer.contact.phone,
    contactName: customer.contact.name,
    contactFax: customer.contact.fax,
    billingAdressCombo: `${billingStreet} ${billingRoute} ${billignCity} ${billingState} ${billingZipCode}`,
    shippingAddressCombo: `${shippingStreet} ${shippingRoute} ${shippingCity} ${shippingState} ${shippingZipCode}`,
    customerReqPo: customer.require_po,
    freeHandling: customer.free_handling,
    aftermarketName: customer.aftermarket_network?.name,
    aftermarketId: customer.aftermarket_network?.network_num,
    aftermarketBillingAddress: customer.aftermarket_network?.billing_address,
    allowCreditCheck: customer.allow_credit_check,
    aRBalance: customer.a_r_balance,
    creditLimit: customer.credit_limit,
    billToDealer: customer.bill_to_dealer,
    mainCity: shippingCity,
    mainState: shippingState,
    billingZipCode,
    shippingZipCode,
    comments,
    terms: customer.terms,
    prepaidWeight: customer.prepaid_weight
      ? customer.prepaid_weight
      : PREPAID_WEIGHT,
    useShippingRules: customer.use_shipping_rules,
    hasXRefItems: customer.has_xref_items,
    itemXRefCode: customer.item_xref_code,
  };
};

export const formatVendorCategoryDiscountSchedules = (discountSchedules) => {
  let schedulesData = [];

  for (let i = 0; i < 10; i++) {
    let columnData = [];

    for (let j = 0; j < 5; j++) {
      if (discountSchedules.length <= i * 5 + j) {
        columnData.push({});
      } else {
        columnData.push(discountSchedules[i * 5 + j]);
      }
    }

    schedulesData.push(columnData);
  }

  return schedulesData;
};
