import { memo, useCallback, useRef, createRef, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { TextField, Autocomplete } from "@mui/material";
import { formStyle } from "../../../theming/formStyle";
import DataGridDateFilter from "./DataGridDateFilter";

function DataGridFilters({
  headerAttributes,
  fetchData,
  setLoading,
  customFilters,
  dateFilter,
}) {
  const [focusOnDate, setFocusOnDate] = useState(false);
  const elementsRef = useRef(headerAttributes.map(() => createRef()));
  let timeoutId;

  const handleFilterRequest = useCallback(
    async (id, value) => {
      setLoading(true);
      let parameters = [];
      const customParameters = customFilters.map((c) => {
        if (c.id === id) {
          return {
            ...c,
            value,
          };
        }
        return c;
      });

      if (customParameters.length === 0) {
        parameters.push({ id, value });
      } else {
        const exists = customParameters.find((c) => c.id === id);
        if (!exists) {
          parameters = [...customParameters, { id, value }];
        } else {
          parameters = customParameters;
        }
      }

      await fetchData(parameters);
      setLoading(false);
    },
    [customFilters, fetchData, setLoading],
  );

  const onKeyDownHandler = (event, index) => {
    if (event.key === "Enter") {
      const fieldsRefs = elementsRef.current.filter((e) => e.current);
      const lastFieldName = fieldsRefs[fieldsRefs.length - 1].current.name;

      let i = index + 1;
      while (i < elementsRef.current.length) {
        if (elementsRef.current[i].current) {
          elementsRef.current[i].current.focus();
          break;
        }
        i++;
      }

      if (event.target.name === lastFieldName) {
        setFocusOnDate(true);
      }
    }
  };

  return (
    <Row className="mb-3">
      {headerAttributes.map((h, index) => {
        if (h.searchable && h.searchable?.type === "text") {
          return (
            <Col sm={h.searchable?.width} key={h.id}>
              <TextField
                className="mb-4"
                
                name={h.id}
                label={h.searchable?.label}
                onChange={(event) => {
                  clearTimeout(timeoutId);
                  timeoutId = setTimeout(() => {
                    handleFilterRequest(h.id, event.target.value);
                  }, 1000);
                }}
                style={formStyle.inputStyle}
                InputLabelProps={formStyle.inputLabelProps}
                inputRef={elementsRef.current[index]}
                onKeyDown={(event) => onKeyDownHandler(event, index)}
              />
            </Col>
          );
        }

        if (h.searchable && h.searchable.type === "select") {
          return (
            <Col sm={h.searchable?.width}>
              <Autocomplete
                disableClearable
                className="autocomplete-input"
                options={h.searchable?.options}
                onChange={async (_e, value) =>
                  handleFilterRequest(h.id, value.id)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name={h.id}
                    style={formStyle.inputStyle}
                    InputLabelProps={formStyle.inputLabelProps}
                    inputRef={elementsRef.current[index]}
                    onKeyDown={(event) => onKeyDownHandler(event, index)}
                    label={h.searchable?.label}
                  />
                )}
                openOnFocus
              />
            </Col>
          );
        }
        return null;
      })}
      {dateFilter?.show && (
        <DataGridDateFilter
          handleFilterRequest={handleFilterRequest}
          dateFilter={dateFilter}
          focusOnDate={focusOnDate}
          setFocusOnDate={(value) => setFocusOnDate(value)}
        />
      )}
    </Row>
  );
}

export default memo(DataGridFilters);
