import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiGet } from "../../../api/apiGet";
import {
  extractIdFromRefUrl,
  formatErrorMessage,
} from "../../../helpers/helperFunctions";
import { apiPut } from "../../../api/apiPut";
import { apiDelete } from "../../../api/apiDelete";
import { getFreightLineById } from "../freight/freightSlice";

/**
 * getBols
 *
 * Get Bill of Ladings by filter
 *
 * @param { object } data filter object for BoL
 * @return { object } bols results from the api
 */
export const getBols = createAsyncThunk("bols/all", async (data, thunkAPI) => {
  try {
    const response = await apiGet.getBolList(data);

    return {
      total: response.count,
      data: response.results,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({
      axiosError: error,
      customMsg: "Error getting the Bill of Ladings.",
    });
  }
});

export const getBolById = createAsyncThunk(
  "bols/getById",
  async (bolId, thunkAPI) => {
    try {
      const res = await apiGet.getBolById(bolId);

      return res;
    } catch (error) {
      console.log(error);
    }
  },
);

export const getAppendedOrders = createAsyncThunk(
  "bol/appenedOrders",
  async ({ suborders }, thunkAPI) => {
    try {
      const {
        bol: { bol },
      } = thunkAPI.getState();

      const suborderIDs = suborders.map((suborder) => {
        const splits = suborder.split("/");
        return Number(splits[splits.length - 2]);
      });
      const filteredSuborderIDs = suborderIDs.filter(
        (id) => id !== Number(bol.bill_of_lading),
      );

      const orders = await Promise.all(
        filteredSuborderIDs.map(async (suborderID) => {
          const subOrder = await apiGet.subOrderById(suborderID);

          return subOrder;
        }),
      );

      return { orders };
    } catch (error) {
      return thunkAPI.rejectWithValue({
        axiosError: error,
        customMsg: "Error getting the appended orders for BoL.",
      });
    }
  },
);

export const getAppendableOrders = createAsyncThunk(
  "bol/appendableOrders",
  async ({ customerNum, warehouseId, page }, thunkAPI) => {
    try {
      const {
        bol: { bol },
      } = thunkAPI.getState();

      const res = await apiGet.getBolList({
        bill_of_lading: bol.bill_of_lading,
        warehouse_id: warehouseId,
        customer_num: customerNum,
        status: [-1, 0, 1],
        appended: false,
        appendable: true,
        page,
      });

      const suborderIDs = res.results.map((v) => Number(v.bill_of_lading));
      const filteredSuborderIDs = suborderIDs.filter(
        (id) => id !== Number(bol.bill_of_lading),
      );

      const orders = await Promise.all(
        filteredSuborderIDs.map(async (suborderID) => {
          const subOrder = await apiGet.subOrderById(suborderID);

          return subOrder;
        }),
      );

      return { orders };
    } catch (error) {
      return thunkAPI.rejectWithValue({
        axiosError: error,
        customMsg: "Error getting the appendable orders for BoL.",
      });
    }
  },
);

/**
 * appendOrdersToBol
 *
 * Append a BoL to a suborder
 * @param { object } data has fields for suborder to append to
 */
export const appendOrdersToBol = createAsyncThunk(
  "bols/Append",
  async ({ customerOrderNum, subOrderIds, company }, thunkAPI) => {
    try {
      const res = await apiPut.appendOrdersToBol({
        customerOrderNum,
        subOrderIds,
        company,
      });

      return res.data;
    } catch (error) {
      if (error?.response?.data) {
        return thunkAPI.rejectWithValue({
          axiosError: error,
          customMsg: (
            <div>
              <p>Error appending the order.</p>
              {formatErrorMessage(error?.response?.data)}
            </div>
          ),
        });
      }

      return thunkAPI.rejectWithValue({
        axiosError: error,
        customMsg: "Error appending the order.",
      });
    }
  },
);

export const resetBols = createAsyncThunk(
  "bols/reset",
  async ({ customerOrderNum, subOrderIds }, thunkAPI) => {
    try {
      const res = await apiDelete.resetBol({ customerOrderNum, subOrderIds });

      return res.data;
    } catch (error) {
      if (error?.response?.data) {
        return thunkAPI.rejectWithValue({
          axiosError: error,
          customMsg: (
            <div>
              <p>Error resetting the order.</p>
              {formatErrorMessage(error?.response?.data)}
            </div>
          ),
        });
      }

      return thunkAPI.rejectWithValue({
        axiosError: error,
        customMsg: "Error resetting the order.",
      });
    }
  },
);

export const defaultBoL = {
  bill_of_lading: "",
  total_sum: 0,
  weight_sum: 0,
  cost_sum: 0,
  warehouse_id: 0,
  warehouse_name: "TN",
  customer_name: "",
  customer_id: 0,
  customer: "",
  freight_line: "",
  freight_line_item: {},
  suborders: [],
};

const initialState = {
  total: 0,
  bol: defaultBoL,
  bols: [],
  suborders: [],
  appendedOrders: [],
  appendableOrders: [],
  loading: false,
  appendedLoading: false,
  appendableLoading: false,
  appending: false,
};

export const bolSlice = createSlice({
  name: "bolSlice",
  initialState,
  reducers: {
    setBol: (state, action) => {
      return {
        ...state,
        bol: action.payload,
      };
    },
    resetAppendedOrders: (state) => {
      state.appendedOrders = [];
    },
    resetAppendableOrders: (state) => {
      state.appendableOrders = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBols.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getBols.fulfilled, (state, action) => {
        state.total = action.payload.total;
        state.bols = action.payload.data.map((bol) => ({
          ...bol,
          customer_id: extractIdFromRefUrl(bol.customer),
          freight_line_num: extractIdFromRefUrl(bol.freight_line),
        }));

        state.loading = false;
      })
      .addCase(getBolById.fulfilled, (state, action) => {
        state.bol = action.payload;
      })
      .addCase(getAppendedOrders.pending, (state, action) => {
        state.appendedLoading = true;
      })
      .addCase(getAppendedOrders.fulfilled, (state, action) => {
        state.appendedOrders = action.payload.orders ?? [];
        state.appendedLoading = false;
      })
      .addCase(getAppendableOrders.pending, (state, action) => {
        state.appendableLoading = true;
      })
      .addCase(getAppendableOrders.fulfilled, (state, action) => {
        state.appendableOrders = action.payload.orders ?? [];
        state.appendableLoading = false;
      })
      .addCase(appendOrdersToBol.pending, (state, action) => {
        state.appending = true;
      })
      .addCase(appendOrdersToBol.fulfilled, (state, action) => {
        const splits = action.payload.customer.split("/");
        const customerId = Number(splits[splits.length - 2]);

        state.bol = { ...action.payload, customer_id: customerId };
        state.appending = false;
      })
      .addCase(getFreightLineById.fulfilled, (state, action) => {
        state.bol.freight_line_item = action.payload;
      });
  },
});

export const { setBol, resetAppendedOrders, resetAppendableOrders } =
  bolSlice.actions;

export default bolSlice.reducer;
