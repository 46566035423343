import { memo, useState, useEffect } from "react";
import TableSortLabel from "@mui/material/TableSortLabel";
import DataGridFilters from "./DataGridFilters";
import Spinner from "react-bootstrap/Spinner";

import {
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Table,
  Tooltip,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Alert,
} from "@mui/material";

import Tr from "./Tr";
import StyledTableCell from "./StyledTableCell";
import Paginator from "../Paginator/Paginator";
import { red } from "@mui/material/colors";
import { APPROVED_ORDER_STATUS, COMPLETED_ORDER_STATUS, IN_PROGRESS_ORDER_STATUS, INVOICED_ORDER_STATUS, ORDER_STATUS, PENDING_ORDER_STATUS, POSTED_ORDER_STATUS } from "../../../helpers/const";

/**
 *  Memo function to avoid this component rerender at least that the list
 *  or one of the props has been updated
 */
function areEqual(prevProps, nextProps) {
  const listsAreEqual = prevProps.dataList.find((prevList, index) => {
    if (prevList?.id !== nextProps.dataList[index]?.id) {
      return false;
    }
    return true;
  });

  const headerIsEqual = prevProps.headerAttributes.find((prevList, index) => {
    if (prevList?.id !== nextProps.dataList[index]?.id) {
      return false;
    }
    return true;
  });

  if (
    headerIsEqual &&
    listsAreEqual &&
    prevProps.hideId === nextProps.hideId &&
    prevProps.rowsPerPage === nextProps.rowsPerPage &&
    prevProps.total === nextProps.total &&
    prevProps.defaultOrder === nextProps.defaultOrder &&
    prevProps.defaultOrderBy === nextProps.defaultOrderBy
  ) {
    return true;
  }
  return false;
}

function DataGrid({
  headerAttributes,
  dataList,
  hideId,
  rowsPerPage,
  total,
  defaultOrder,
  defaultOrderBy,
  fetchData,
  dateFilter,
  isOrders,
}) {
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [order, setOrder] = useState("desc");
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [customFilters, setCustomFilters] = useState([]);
  const [selected, setSelected] = useState([0, 1, 2])
  const [showString, setShowString] = useState("")


  const updateStatuses = (value) => {
    if (selected.includes(parseInt(value))) {
      setSelected(selected.filter(val => val !== parseInt(value)))
    } else {
      setSelected([parseInt(value), ...selected])
    }

  }

  /**
   * Function to set the order of a column
   */
  const handleRequestSort = async ({ property }) => {
    setLoading(true);
    const isAsc = orderBy === property && order === "asc";
    await fetchData({
      page,
      orderBy: property,
      sortOrder: isAsc ? "desc" : "asc",
      customParameters: customFilters,
      stat: selected
    });

    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setLoading(false);
  };

  const handleFilterChange = async (customParameters) => {
    setPage(0);
    setLoading(true);
    await fetchData({
      page: 0,
      orderBy,
      sortOrder: order,
      customParameters,
      stat: selected,
    });
    setCustomFilters(customParameters);
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      await fetchData({ orderBy: defaultOrderBy, sortOrder: defaultOrder, stat: selected });
      setLoading(false);
    })();
  }, [fetchData, defaultOrder, defaultOrderBy]);

  useEffect(() => {
    if (defaultOrder) {
      setOrder(defaultOrder);
    }
  }, [defaultOrder]);

  useEffect(() => {
    if (defaultOrderBy) {
      setOrderBy(defaultOrderBy);
    }
  }, [defaultOrderBy]);

  useEffect(() => {
    if (selected.length > 0) {
      let res = "Showing orders in status: "
      let changed = false

      for (let i = 0; i < selected.length; i++) {
        changed = true
        let c = selected[i]
        let endstring = i === selected.length - 1 ? '"' : '", '
        let begstring = i === selected.length - 1 ? ' or "' : '"'
        begstring = i === 0 && i === selected.length - 1 ? '"' : begstring

        res += begstring + ORDER_STATUS.find((stat) => stat.id === c).label + endstring
      }
      if (!changed) {
        res = "No order status selected!"
      }

      setShowString(res)

    }


    handleFilterChange(customFilters)
  }, [selected])

  return (
    
    <section >

        {isOrders && 
        <div>
          {<Alert severity="info">{showString}</Alert>}
          {selected.includes(POSTED_ORDER_STATUS.id) && <Alert className="warning-box" severity="error">Posted orders selected, this will result in slower load times.</Alert>}
<div className="status-boxes">
        <FormGroup>
          <FormControlLabel
            label={PENDING_ORDER_STATUS.label}
            value={1}
            control={<Checkbox
              value={PENDING_ORDER_STATUS.id}
              checked={selected.includes(PENDING_ORDER_STATUS.id)}
              onClick={(e) => updateStatuses(e.target.value)}
            />}
          />
        </FormGroup>
        <FormGroup>
          <FormControlLabel
            label={APPROVED_ORDER_STATUS.label}
            value={1}
            control={<Checkbox
              value={APPROVED_ORDER_STATUS.id}
              checked={selected.includes(APPROVED_ORDER_STATUS.id)}
              onClick={(e) => updateStatuses(e.target.value)}
            />}
          />
        </FormGroup>
        <FormGroup>
          <FormControlLabel
            label={IN_PROGRESS_ORDER_STATUS.label}
            value={1}
            control={<Checkbox
              value={IN_PROGRESS_ORDER_STATUS.id}
              checked={selected.includes(IN_PROGRESS_ORDER_STATUS.id)}
              onClick={(e) => updateStatuses(e.target.value)}
            />}
          />
        </FormGroup>
        <FormGroup>
          <FormControlLabel
            label={POSTED_ORDER_STATUS.label}
            value={1}
            control={<Checkbox
              value={POSTED_ORDER_STATUS.id}
              checked={selected.includes(POSTED_ORDER_STATUS.id)}
              onClick={(e) => updateStatuses(e.target.value)}
              sx={{
                color: red[800],
                '&.Mui-checked': {
                  color: red[600],
                },
              }}
            />}
          />
        </FormGroup>
      </div></div>}
      <DataGridFilters
        headerAttributes={headerAttributes}
        customFilters={customFilters}
        dateFilter={dateFilter}
        setLoading={(state) => {
          setLoading(state);
        }}
        fetchData={handleFilterChange}
      />
      <TableContainer component={Paper} style={{ position: "relative" }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {headerAttributes ? (
                headerAttributes.map((th) => {
                  if (th?.hidden) {
                    return <></>;
                  } else {
                    const tableCell = (
                      <StyledTableCell style={{ width: th.width }} key={th.id}>
                        {th?.sortable ? (
                          <TableSortLabel
                            active={orderBy === th.id}
                            direction={orderBy === th.id ? order : "asc"}
                            onClick={() =>
                              handleRequestSort({
                                property: th.id,
                                backendSortable: th.backendSortable,
                              })
                            }
                          >
                            {th.label}
                          </TableSortLabel>
                        ) : (
                          th.label
                        )}
                      </StyledTableCell>
                    );

                    return th?.tooltip ? (
                      <Tooltip title={th.tooltip} placement="top" arrow>
                        <span>{tableCell}</span>
                      </Tooltip>
                    ) : (
                      tableCell
                    );
                  }
                })
              ) : (
                <></>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading && (
              <>
                {dataList.map((row) => (
                  <Tr key={row.id} data={row} hideId={hideId} />
                ))}
              </>
            )}
          </TableBody>
        </Table>
        {loading && (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ width: "100%", minHeight: 150 }}
          >
            <Spinner animation="grow" variant="primary" />
          </div>
        )}
        <Paginator
          rowsPerPage={rowsPerPage}
          totalOrders={total}
          page={page}
          setPage={async (p) => {
            setPage(p);
            setLoading(true);
            await fetchData({
              page: p,
              orderBy,
              sortOrder: order,
              customParameters: customFilters,
              stat: selected,
            });
            setLoading(false);
          }}
        />
      </TableContainer>
    </section>
  );
}

export default memo(DataGrid, areEqual);
