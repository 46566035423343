import React, { memo, useEffect, useState } from "react";
import {
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Table as MuiTable,
  Box,
  CircularProgress,
  Typography,
} from "@mui/material";
import { ClickAwayListener } from "@mui/base";
import TableSortLabel from "@mui/material/TableSortLabel";

import Tr from "./Tr";
import StyledTableCell from "./StyledTableCell";
import { deepEqual } from "../../../helpers/helperFunctions";

/**
 *  Memo function to avoid this component rerender at least that the list
 *  or one of the props has been updated
 */
function areEqual(prevProps, nextProps) {
  const listsAreEqual = deepEqual(prevProps.dataList, nextProps.dataList);

  const headerIsEqual = deepEqual(
    prevProps.headerAttributes,
    nextProps.headerAttributes,
  );

  if (
    listsAreEqual &&
    headerIsEqual &&
    prevProps.selector === nextProps.selector &&
    prevProps.onSelectRow === nextProps.onSelectRow &&
    prevProps.selectedItem === nextProps.selectedItem &&
    prevProps.hideId === nextProps.hideId &&
    prevProps?.getFocus === nextProps?.getFocus &&
    prevProps?.prevFocusRef === nextProps?.prevFocusRef &&
    prevProps?.nextFocusRef === nextProps?.nextFocusRef &&
    prevProps?.setFocusOnDataTable === nextProps?.setFocusOnDataTable &&
    prevProps?.onSelectionChange === nextProps?.onSelectionChange &&
    prevProps?.isLoading === nextProps?.isLoading &&
    prevProps?.selectedItems === nextProps?.selectedItems
  ) {
    return true;
  }
  return false;
}

function Table({
  selector,
  headerAttributes,
  dataList,
  onSelectRow,
  selectedItem,
  hideId,
  getFocus,
  nextFocusRef,
  prevFocusRef,
  setFocusOnDataTable,
  onSelectionChange,
  isLoading,
  selectedItems,
}) {
  // States
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("asc");
  const [formattedList, setFormattedList] = useState([]);
  const [focusedIndex, setFocusedIndex] = useState();
  const [selectedIndex, setSelectedIndex] = useState();

  useEffect(() => {
    setFormattedList(dataList);
  }, [dataList]);

  /**
   * Add selected attribute for each row of the datalist
   */
  useEffect(() => {
    if (selector === "checkbox") {
      setSelectedIndex(
        formattedList.map((item) => selectedItems.includes(item.id)),
      );
    } else {
      const selectedItemIndex = formattedList.findIndex(
        (item) => item.id === selectedItem,
      );

      setSelectedIndex(selectedItemIndex);
    }
  }, [formattedList, selector, selectedItem, selectedItems]);

  /**
   * Focus cursor on the first radio button
   */
  useEffect(() => {
    if (getFocus) {
      if (selectedIndex > 0) {
        setFocusedIndex(selectedIndex);
      } else {
        setFocusedIndex(0);
      }
    }
  }, [getFocus, selectedIndex]);

  useEffect(() => {
    if (selectedIndex > 0) {
      setFocusedIndex(selectedIndex);
    }
  }, [selectedIndex]);

  /**
   * Function to set the order of a column
   */
  const handleRequestSort = ({ property, type }) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);

    const orderedData = formattedList.slice().sort((a, b) => {
      if (isAsc) {
        if (type === "number") {
          if (Number(a[property]) < Number(b[property])) {
            return -1;
          }

          if (Number(a[property]) > Number(b[property])) {
            return 1;
          }
        }
        return Number(a[property]) - Number(b[property]);
      } else {
        if (type === "number") {
          if (Number(a[property]) > Number(b[property])) {
            return -1;
          }

          if (Number(a[property]) < Number(b[property])) {
            return 1;
          }
        }
        return Number(b[property]) - Number(a[property]);
      }
    });

    setFormattedList(orderedData);
  };

  const handleSelectRow = (id, data) => {
    if (selector === "checkbox") {
      const newSelectedItems = selectedItems.includes(id)
        ? selectedItems.filter((item) => item !== id)
        : [...selectedItems, id];

      onSelectionChange(newSelectedItems);
    } else {
      onSelectRow(id, data);
    }
  };

  /**
   * Key down handler on the radio button
   */
  const handleKeyDown = (event) => {
    if (event.shiftKey && event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();

      if (selectedIndex > 0) {
        nextFocusRef?.current.focus();
      } else {
        prevFocusRef?.current.focus();
      }

      handleFocusOutTable(selectedIndex);
    }

    if (
      (!event.shiftKey && event.key === "Enter") ||
      event.key === "ArrowDown"
    ) {
      event.preventDefault();

      if (focusedIndex < formattedList.length - 1) {
        setFocusedIndex((prev) => prev + 1);
      } else {
        nextFocusRef?.current.focus();
      }
    }

    if (event.key === "ArrowUp") {
      event.preventDefault();
      setFocusedIndex((prev) => (prev ? prev - 1 : 0));
    }

    if (event.code === "Space") {
      event.preventDefault();

      if (focusedIndex !== selectedIndex) {
        setFocusedIndex(-1);
        onSelectRow(formattedList[focusedIndex]?.id);
      }
    }
  };

  const handleFocusOutTable = () => {
    setFocusedIndex(-1);
    if (setFocusOnDataTable) {
      setFocusOnDataTable(false);
    }
  };

  return (
    <ClickAwayListener onClickAway={handleFocusOutTable}>
      <TableContainer
        component={Paper}
        style={{ position: "relative" }}
        sx={{ maxHeight: 450 }}
      >
        <MuiTable aria-label="simple table">
          <TableHead>
            <TableRow>
              {headerAttributes &&
                headerAttributes.map((th) => (
                  <StyledTableCell
                    key={th.id}
                    sortDirection={orderBy === th.id ? order : false}
                  >
                    {th.sortable || th.backendSortable ? (
                      <TableSortLabel
                        active={orderBy === th.id}
                        direction={orderBy === th.id ? order : "asc"}
                        onClick={() =>
                          handleRequestSort({
                            property: th.id,
                            type: th.type,
                          })
                        }
                      >
                        {th.label}
                      </TableSortLabel>
                    ) : (
                      th.label
                    )}
                  </StyledTableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <StyledTableCell colSpan={headerAttributes.length}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height={200}
                  >
                    <CircularProgress />
                  </Box>
                </StyledTableCell>
              </TableRow>
            ) : formattedList.length === 0 ? (
              <TableRow>
                <StyledTableCell colSpan={headerAttributes.length}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height={200}
                  >
                    <Typography variant="body1">No data to display.</Typography>
                  </Box>
                </StyledTableCell>
              </TableRow>
            ) : (
              formattedList.map((row, index) => (
                <Tr
                  key={row.id}
                  data={row}
                  selector={selector}
                  hideId={hideId}
                  isFocused={index === focusedIndex}
                  isSelected={
                    selector === "checkbox"
                      ? selectedItems.includes(row.id)
                      : index === selectedIndex
                  }
                  setSelectedRow={handleSelectRow}
                  handleKeyDown={handleKeyDown}
                />
              ))
            )}
          </TableBody>
        </MuiTable>
      </TableContainer>
    </ClickAwayListener>
  );
}

export default memo(Table, areEqual);
