import { Alert } from "@mui/material"

const Claims = () => {

    return (
        <>
            <Alert severity="warning" className="mt-5">
                There is nothing here yet, check back soon for updates.
            </Alert>
            <a href="/">Back to home</a>
        </>
    )
}

export default Claims